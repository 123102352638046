import React, { useContext } from "react";
import CartContext from "../../context/cart";
import css from "./Slider.module.css";

const Slider = ({ checkpoints, minimum, maximum, title }) => {
  const { divide, multiply, subtract } = useContext(CartContext);

  return (
    <div className={css.main}>
      <div className={css.labels}>
        <strong>${minimum}</strong>
        <strong>{title}</strong>
        <strong>${maximum}</strong>
      </div>
      <div className={css.bar}>
        {checkpoints.map((checkpoint) => {
          let normalizedValue = multiply(
            divide(
              subtract(checkpoint.value, minimum, checkpoint.value - minimum),
              subtract(maximum, minimum)
            ),
            100
          );

          let alignItems;

          if (normalizedValue >= 16 && normalizedValue < 84)
            alignItems = "center";
          else if (normalizedValue < 16) alignItems = "start";
          else alignItems = "end";

          let style = {
            left: `${normalizedValue}%`,
            alignItems,
          };

          return (
            <div className={css.checkpoint_container} style={style}>
              <span className="o64">{checkpoint.value}</span>
              <div className={css.checkpoint} />
              <span className="o64">{checkpoint.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Slider;
