import React from "react";
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";

import bears from "./pictures/bears.webp";
import beach from "./pictures/beach.webp";
import boobs from "./pictures/boobs.webp";
import corn from "./pictures/corn.webp";
import clouds1 from "./pictures/clouds1.jpg";
import doctor from "./pictures/doctor.jpg";
import dolf from "./pictures/dolf.jpg";
import donuts from "./pictures/donuts.jpg";
import emma from "./pictures/emma.jpg";
import flower from "./pictures/flower.webp";
import jonah from "./pictures/jonah.webp";
import max from "./pictures/max.webp";
import meat from "./pictures/meat.webp";
import parents from "./pictures/parents.jpg";
import sleep from "./pictures/sleep.jpg";

const Photography = () => {
  const portraits = [
    {
      src: max,
      alt: "a brother and sister hugging",
      description:
        "taken mere moments before emma left on her mission to rome, italy.",
    },
    {
      src: emma,
      alt: "emma",
      description: "a candid pic i got of emma on a random sunday afternoon.",
    },
    {
      src: jonah,
      alt: "a brother and sister hugging",
      description:
        "taken mere moments before emma left on her mission to rome, italy.",
    },
    {
      src: parents,
      alt: "parents",
      description: "my mom and dad!",
    },
    {
      src: corn,
      alt: "anika standing in front of corn.",
      description: "look how cute my wife is!",
    },
    {
      src: sleep,
      alt: "anika sleeping.",
      description: "she really just looks like that when she's sleeping. wow.",
    },
    {
      src: doctor,
      alt: "anika on a doctor table",
      description:
        "proof that anika looks good at all times—even in an emergency.",
    },
    {
      src: beach,
      alt: "anika at the beach",
      description: "if this is a dream... don't wake me up.",
    },
    {
      src: bears,
      alt: "three young children in bear costumes",
      description: "these kids are so cute and an absolute blast to be around.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/portrait/me.webp",
      alt: "a close-up shot of my face. (i'm not describing my face to you; sorry.)",
      description:
        "a self-portrait i took. i think i really nailed the 'mysterious guy lurking in the corner' vibe i was going for.",
    },
    {
      src: dolf,
      alt: "two people celebrating",
      description:
        "our dad is good at disc golf... the rest of us are moral support.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/portrait/grateful.webp",
      alt: "me standing in front of a hearth.",
      description: "i staged and took this picture a looooong time ago.",
    },
  ];

  const nature = [
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/jellyfish.webp",
      alt: "a bunch of white jellyfish in stark contrast against a deep blue background.",
      description: "anika's little slice of hell. (she hates jellyfish.)",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/lifestyle/feet.webp",
      alt: "feet prints in sand",
      description:
        "getting a picture like this is NOT easy, i'll tell you that much.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/embers.webp",
      alt: "a close-up shot of burning coals: a stark contrast of pitch black and glowing red.",
      description: "i could stare at embers for hours...",
    },
    {
      src: clouds1,
      alt: "clouds",
      description: "(the sky is the artist here; i just took the picture.)",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/clay/diglett.webp",
      alt: "diglett",
      description: "a diglett i made out of clay!",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/cliff.webp",
      alt: "a dark cliff sloping downward from the dark left to the bottom right, in stark contrast to a sky that starts blue at the top and fades to a bright yellow.",
      description:
        "this gorgeous flower is planted in front of our favorite coffee shop.",
    },
    {
      src: flower,
      alt: "a bright orange flower on a black background",
      description:
        "this gorgeous flower is planted in front of our favorite coffee shop.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/flowers.webp",
      alt: "close-up shot of flowers: a stark contrast between dark green, bright yellow, and deep pink.",
      description: "(i didn't even edit this one!)",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/wallflowers.webp",
      alt: "flowers in vases hanging from a white wall.",
      description: "the perks of being a what now?",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/raja.webp",
      alt: "red, white, and blue betta fish",
      description:
        "anika and i love betta fish. everyone, this is raja. raja, everyone.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/eevee2.webp",
      alt: "a dog lying on the floor with its arms crossed like the sassy little lady she is.",
      description: "it's eevee baby!",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/maple2.webp",
      alt: "a dog sitting on the sidewalk",
      description: "it's maple baby!",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/maple_eevee2.webp",
      alt: "two dogs standing on an ottoman. they're both wearing light blue and pink (like cotton candy) vests with brightly-colored flowers and rainbows on them.",
      description: "our sweet babies.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/maple_eevee.webp",
      alt: "two dogs lying facing each other with their arms intertwined.",
      description: "they're best friends!!.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/eevee.webp",
      alt: "a dog sitting on the ground staring up at you with classic puppy-dog eyes.",
      description: "queen eevee!",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/nature/maple.webp",
      alt: "a dog lying on a couch",
      description: "maple is the sweetest little loaf (when she wants to be).",
    },
  ];

  const food = [
    {
      src: "https://storage.googleapis.com/squidslippers-media/food/brussels_sprouts_bacon.webp",
      alt: "close-up shot of colorful donuts",
      description: "an absolutely fantastic dish!",
    },
    {
      src: meat,
      alt: "diced steak in a pan",
      description:
        "steak: the breakfast of champion. (it's me; i'm the champion.)",
    },
    {
      src: donuts,
      alt: "close-up shot of colorful donuts",
      description: "our favorite donut shop sells the cutest little treats.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/food/steak.webp",
      alt: "diced steak in a pan",
      description:
        "steak: the breakfast of champion. (it's me; i'm the champion.)",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/food/pancakes.webp",
      alt: "banana pancakes",
      description: "i don't love pancakes, but i do like them.",
    },
  ];

  const product = [
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/white.jpg",
      alt: "a handbag made of white leather with a brilliant pink and white stained glass panel in the center.",
      description: "aurora white, by tj holdman.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/sage.jpg",
      alt: "a handbag made of sage leather with a brilliant brown and white stained glass panel in the center.",
      description: "aurora sage, by tj holdman.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/crimson.jpg",
      alt: "a handbag made of crimson leather with a brilliant yellow and pink stained glass panel in the center.",
      description: "aurora crimson, by tj holdman.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/caramel.jpg",
      alt: "a handbag made of caramel leather with a brilliant brown and orange stained glass panel in the center.",
      description: "aurora caramel, by tj holdman.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/mist.jpg",
      alt: "a handbag made of gold metal with a brilliant wavy white stained glass panel in the center.",
      description: "opalena mist, by tj holdman.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/lime.jpg",
      alt: "a handbag made of gold metal with a brilliant green and white stained glass panel in the center.",
      description: "opalena lime, by tj holdman.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/flame.jpg",
      alt: "a handbag made of gold metal with a brilliant red and white stained glass panel in the center.",
      description: "opalena crimson, by tj holdman.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/sky.jpg",
      alt: "a handbag made of gold metal with a brilliant sky blue stained glass panel in the center.",
      description: "opalena sky, by tj holdman.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/monocular_set.jpg",
      alt: "test",
      description: "i found this gem at d.i.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/monocular.jpg",
      alt: "a multicolored cube on a white background",
      description: "i found this gem at d.i.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/monocular_stand.jpg",
      alt: "test",
      description: "i found this gem at d.i.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/cmy_cube.jpg",
      alt: "a multicolored cube on a white background",
      description: "this little guy sits on my desk.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/hydroflask.jpg",
      alt: "a teal 24-ounce hydroflask with a wide-mouth lid.",
      description: "my water bottle.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/product/dog_food.jpg",
      alt: "a close-up shot of dog food: rich, brown, triangular-shaped kibble.",
      description: "a cmy cube",
    },
  ];

  const pictures = [
    {
      src: "https://storage.googleapis.com/squidslippers-media/lifestyle/streamers.webp",
      alt: "a close-up of multicolored streamers that were dangling from the ceiling and blowing in the wind.",
      description: "party time!",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/lifestyle/harry.webp",
      alt: "harry mack in concert",
      description:
        "this was one of the best concerts—and most impressive things—i've ever witnessed.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/lifestyle/nightstand.webp",
      alt: "black nightstand with a purple orchid, gold tabletop lamp, and black zen garden with white sand with rainbow-colored stones buried wtihin. To the right of that is a white bed sheet on top of a bed.",
      description: "my little slice of heaven.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/lifestyle/zen.webp",
      alt: "a zen garden.",
      description: "the zen garden max made me. it's awesome.",
    },
    {
      src: boobs,
      alt: "an odometer reading 280085",
      description: "2 BOOBS!! oh yeah!!",
    },
  ];

  return (
    <div className="main">
      <Intro
        primary="SAY CHEEEEESE"
        secondary="i'm not a professional or anything, but i do have a nice camera, a
            basic understanding of photography, a lot of free time, and a few
            neat pictures to show for it."
      />
      <div className="column p01">
        <h2>PRODUCT</h2>
        <Pictures pictures={product} />

        <h2 style={{ marginTop: "4rem" }}>NATURE</h2>
        <Pictures pictures={nature} />

        <h2 style={{ marginTop: "4rem" }}>PORTRAIT</h2>
        <Pictures pictures={portraits} />

        <h2 style={{ marginTop: "4rem" }}>FOOD</h2>
        <Pictures pictures={food} />

        <h2 style={{ marginTop: "4rem" }}>LIFESTYLE</h2>
        <Pictures pictures={pictures} />
      </div>
    </div>
  );
};

export default Photography;
