import React, { createContext, useCallback, useState } from "react";
import css from "./Context.module.css";

const NotificationsContext = createContext({
  addNotification: () => {},
  array: [],
});

export const NotificationsContextProvider = (props) => {
  const [notifications, setNotifications] = useState([]);

  const removeNotification = useCallback(
    (id) => {
      setNotifications((old) => {
        let newNotifications = old.filter((item) => item.id !== id);
        return newNotifications;
      });
    },
    [setNotifications]
  );

  const addNotification = useCallback((notification) => {
    setNotifications((old) => {
      return [...old, notification];
    });

    const timer = setTimeout(() => {
      setNotifications((old) => {
        return old?.slice(1);
      });

      clearTimeout(timer);
    }, notification?.showTime * 1000 || 5000);
  }, []);

  const array = (
    <ul className={css.notifications}>
      {notifications?.map((notification, index) => {
        return (
          <li
            key={notification.id || new Date().getTime() + index}
            onClick={() => {
              removeNotification(notification.id);
            }}
            className={`${css.notification} ${css[notification.type]}`}
          >
            <strong className={css.primary}>{notification.primary}</strong>
            <span className={css.secondary}>{notification.secondary}</span>
          </li>
        );
      })}
    </ul>
  );
  const context = {
    addNotification,
    array,
  };

  return (
    <NotificationsContext.Provider value={context}>
      {props.children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContext;
