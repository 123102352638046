import React, { useContext } from "react";
import { Link } from "react-router-dom";
import CartContext from "../../context/cart";
import css from "./Cart.module.css";
import Card from "../../components/card/Card";
import WireName from "../../components/wirename/WireName";
import ColoredPaincil from "../../components/coloredpaincil/ColoredPaincil";
import Small from "../../components/intro/Small";
import Diglett from "../../components/diglett/Diglett";

const Cart = () => {
  const { cart, removeFromCart } = useContext(CartContext);
  let total = cart.reduce((acc, curr) => {
    return acc + curr.price;
  }, 0);

  return (
    <div className="main">
      <Small
        primary="YOUR CART"
        secondary={
          cart.length === 0
            ? "is empty."
            : `has ${cart.length} item${cart.length === 1 ? "" : "s"} in it.`
        }
      />
      <div className="column p01">
        {cart.length > 0 && (
          <>
            <ul className={css.list} style={{ alignItems: "center" }}>
              {cart.map((entry) => {
                switch (entry.title) {
                  case "custom wire name":
                    return <WireName name={entry} onClick={removeFromCart} />;
                  case "colored paincil":
                    return (
                      <ColoredPaincil
                        paincil={entry}
                        onClick={removeFromCart}
                      />
                    );
                  case "clay sculpture":
                    return <Diglett />;
                }
              })}
            </ul>
            <div className={css.total}>
              <strong>TOTAL:</strong>
              <strong>${total}</strong>
            </div>
            <Link
              to="/checkout"
              style={{ maxWidth: "32rem" }}
              className="button"
            >
              CONTINUE TO CHECKOUT
            </Link>
          </>
        )}

        {cart.length > 0 ? (
          <h2 style={{ marginTop: "4rem" }}>KEEP SHOPPING</h2>
        ) : (
          <h2>START SHOPPING</h2>
        )}
        <div className="flex wrap">
          <Card
            primary="WIRE NAMES"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/art/wire/falling.png",
              alt: "wire names scattered across a black background",
            }}
            button={
              <Link className="link" to="/wire-names">
                GET SOME
              </Link>
            }
          />

          <Card
            primary="COLORED PAINCILS"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
              alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
            }}
            button={
              <Link className="link" to="/colored-paincils">
                GET ONE
              </Link>
            }
          />

          <Card
            primary="WOOD BURNING"
            button={
              <Link className="link" to="/wood-burning">
                GET ONE
              </Link>
            }
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Cart;
