import React from "react";
import css from "./Checkbox.module.css";

const Checkbox = ({ checked, onClick, primary, secondary, value }) => {
  return (
    <div
      className={`${css.main} ${secondary ? css.main2 : ""}`}
      onClick={() => onClick(value)}
    >
      <button
        type="button"
        className={`${css.checkbox} ${checked ? css.checked : ""}`}
      >
        {checked && (
          <img
            src="https://storage.googleapis.com/squidslippers-media/icons/check.png"
            className={css.check}
          />
        )}
      </button>
      <p className={css.primary}>{primary}</p>
      {secondary && <div className={css.secondary}>{secondary}</div>}
    </div>
  );
};

export default Checkbox;
