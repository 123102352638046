import React, { useContext, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import css from "./Checkout.module.css";
import { useHttp } from "../../helpers/useHttp";
import CartContext from "../../context/cart";
import { loadStripe } from "@stripe/stripe-js";
import { Link, Navigate } from "react-router-dom";
import Form from "./Form";
import WireName from "../../components/wirename/WireName";
import Small from "../../components/intro/Small";

const Checkout = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { sendRequest } = useHttp();
  const { cart, order, createOrder } = useContext(CartContext);

  const stripe = loadStripe(
    process.env.NODE_ENV === "production"
      ? "pk_live_51PG1cBE8JDSpBeryg35KamxhzNgB88h7R8dgOaBYm2RuEjDfGWBWYfnEhVcLnhBUPMtuWR8bphiT25gm3TePTLKi00BzYOqn1C"
      : "pk_test_51PG1cBE8JDSpBerypldkR24EqpIqmTUTtiLT32gnvIeu1eKpAuy2OF3UnCcfM4v44ChBPaHTE11fDepFhR7VUL6o006FU8KLOH"
  );

  useEffect(() => {
    async function getOrder() {
      if (!order) {
        try {
          const order = await sendRequest(
            "payment-intent",
            "POST",
            JSON.stringify(cart)
          );
          createOrder(order);
        } catch (error) {
          setError(true);
        }
      }
    }
    getOrder();
    setLoading(false);
  }, [cart, createOrder, order, sendRequest]);

  const options = {
    clientSecret: order?.payment?.secret,
    appearance: {
      theme: "flat",

      variables: {
        colorBackground: "white",
        fontFamily: "Quicksand",
        colorText: "#161616",
        colorDanger: "#f2d2bd",
        gridRowSpacing: "1rem",
        gridColumnSpacing: "1rem",
      },

      rules: {
        ".Input": {
          backgroundColor: "rgba(255, 255, 255, 0.32)",
          color: "white",
          borderRadius: "2rem",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          padding: "0.55rem 0.8rem",
          fontSize: "1rem",
        },
        ".Input--empty": {
          backgroundColor: "white",
        },
        ".Input--invalid": {
          color: "#bc0000",
          backgroundColor: "#ffb8b8",
        },
        ".Label": { color: "#ffffffa3" },
        ".Label--empty": {
          color: "white",
        },
        ".Label--invalid": {
          color: "#f2d2bd",
        },
        ".CheckboxLabel": { color: "#ffffffa3" },
      },
    },
    fonts: [
      {
        family: "Quicksand",
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap",
        weight: "700",
      },
    ],
  };

  if (error) {
    return (
      <div className={`${css.main} gradient`}>
        <h1>something went wrong</h1>
        <p>
          we weren't able to check you out. please adjust your cart and try
          again.
        </p>
        <Link to="/cart" className="link">
          BACK TO CART
        </Link>
      </div>
    );
  }

  if (loading || !order || !stripe) {
    return (
      <Small primary="PLEASE HOLD..." secondary="while i get things ready." />
    );
  }
  if (cart.length === 0) {
    return <Navigate replace to="/cart" />;
  }

  let wireNames = cart.filter((item) => item.title === "custom wire name");
  let coloredPaincils = cart.filter((item) => item.title === "colored paincil");

  let secondary;

  if (wireNames.length > 0 && coloredPaincils.length > 0) {
    secondary = `buy ${wireNames.length} wire name${
      wireNames.length === 1 ? "" : "s"
    } and ${coloredPaincils.length} colored paincil${
      coloredPaincils.length === 1 ? "" : "s"
    } for $${order.payment.total}.`;
  } else if (wireNames.length > 0) {
    secondary = `buy ${wireNames.length} wire names for $${order.payment.total}.`;
  } else if (coloredPaincils.length > 0) {
    secondary = `buy ${coloredPaincils.length} colored paincils for $${order.payment.total}.`;
  }

  return (
    <Elements stripe={stripe} options={options}>
      <Small primary="CHECKOUT" secondary={secondary} />
      <div className={css.dark}>
        <Form />
      </div>
    </Elements>
  );
};

export default Checkout;
