import React, { useContext, useEffect, useState } from "react";
import css from "./Food.module.css";
import NotificationsContext from "../../context/notifications";
import Accordion from "../../components/accordion/Accordion";

function Timer({ initialMinutes = 1, initialSeconds = 0, primary }) {
  let _initialSeconds = initialSeconds;
  let _initialMinutes = initialMinutes;

  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(false);

  const { addNotification } = useContext(NotificationsContext);

  useEffect(() => {
    let timer;
    if (isActive) {
      timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          clearInterval(timer);
          setIsActive(false);
          setMinutes(_initialMinutes);
          setSeconds(_initialSeconds);
          addNotification({
            primary: "NICE WORK!",
            secondary: "it's time to move on to the next step.",
            type: "success",
          });
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [seconds, minutes, isActive]);

  const start = () => {
    setIsActive(true);
  };

  const reset = () => {
    setIsActive(false);
    setMinutes(_initialMinutes);
    setSeconds(_initialSeconds);
  };

  return (
    <div className={css.timer}>
      {primary}

      <strong className={`${css.time} ${!isActive ? css.time_idle : ""}`}>
        {String(minutes).padStart(1, "0")}:{String(seconds).padStart(2, "0")}
      </strong>

      {isActive ? (
        <button className="link" onClick={reset}>
          RESET
        </button>
      ) : (
        <button className="link" onClick={start}>
          START
        </button>
      )}
    </div>
  );
}

export default Timer;
