import React from "react";
import { Link } from "react-router-dom";

import Pictures from "../../components/pictures/Pictures";
import Card from "../../components/card/Card";
import Small from "../../components/intro/Small";
import Form from "./Form";

const WireNames = () => {
  return (
    <div className="main">
      <Small
        primary="WIRE NAMES"
        secondary="i make high-quality, hand-crafted wire names and sell them for
          $1/letter."
      />

      <div className="column p01" style={{ alignItems: "center" }}>
        <Form />

        <h2 style={{ marginTop: "4rem" }}>FOR EXAMPLE</h2>
        <Pictures
          pictures={[
            {
              src: require("./ed.jpg"),
              alt: "The name Ed made out of wire.",
            },
            {
              src: require("./joy.jpg"),
              alt: "The name Joy made out of wire.",
            },
            {
              src: require("./kaz.jpg"),
              alt: "The name Kaz made out of wire.",
            },
            {
              src: require("./jess.jpg"),
              alt: "The name Jess made out of wire.",
            },
            {
              src: require("./billy.jpg"),
              alt: "The name Billy made out of wire.",
            },
            {
              src: require("./lucas.jpg"),
              alt: "The name Lucas made out of wire.",
            },
            {
              src: require("./isaac.jpg"),
              alt: "The name Isaac made out of wire.",
            },
            {
              src: require("./jacob.jpg"),
              alt: "The name Jacob made out of wire.",
            },
            {
              src: require("./jason.jpg"),
              alt: "The name Jason made out of wire.",
            },
            {
              src: require("./bubbles.jpg"),
              alt: "The name Bubbles made out of wire.",
            },
            {
              src: require("./ernesto.jpg"),
              alt: "The name Ernesto made out of wire.",
            },
          ]}
        />

        <h2 style={{ marginTop: "4rem" }}>MORE TO SHOP</h2>
        <div className="flex wrap">
          <Card
            primary="COLORED PAINCILS"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
              alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
            }}
            button={
              <Link className="link" to="/colored-paincils">
                CHECK IT OUT
              </Link>
            }
          />
          <Card
            primary="WOOD BURNING"
            button={
              <Link className="link" to="/wood-burning">
                GET A WOOD BURNING
              </Link>
            }
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
            }}
          />
        </div>

        <h2 style={{ marginTop: "4rem" }}>MORE ART</h2>
        <Pictures
          key={1}
          pictures={[
            {
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
              description:
                "a friend of mine commissioned this wood burning as a christmas gift for his wife.",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/art/egg.webp",
              alt: "egg",
              description: "a hyper-realistic egg i drew using procreate.",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/logo/ss.jpg",
              alt: "squidslippers",
              description: "my squidslippers logo! i love this little guy!",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/art/pig.webp",
              alt: "pig",
              description: "an acrylic painting i made as a commission.",
            },
          ]}
        />

        <Link className="link" to={"/art"}>
          CHECK IT OUT
        </Link>
      </div>
    </div>
  );
};

export default WireNames;
