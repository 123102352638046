import React from "react";
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";
import Card from "../../components/card/Card";
import Accordion from "../../components/accordion/Accordion";
import { Link } from "react-router-dom";

const SocialMedia = () => {
  return (
    <div className="main">
      <Intro
        primary="SOCIAL MEDIA"
        secondary="i've done well for myself on social media. i've gone viral at least once on every major platform; i've helped more than 30 companies effectively leverage social media marketing; and i still use it to sell products and build brands. if you want to see what i've been up to online..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>AS SEEN ON</h2>
        <div className="flex wrap">
          <Card
            primary="BUZZFEED"
            img={{
              src: require("./pictures/buzzfeed.jpg"),
              alt: "buzzfeed logo",
            }}
            button={
              <a
                className="link"
                href="https://www.buzzfeednews.com/article/remysmidt/aristotle-comes-through"
                target="_blank"
                rel="noreferrer noopener"
              >
                READ THE ARTICLE
              </a>
            }
          />
          <Card
            primary="MEN'S HUMOR"
            img={{
              src: require("./pictures/mens_humor.jpg"),
              alt: "men's humor logo",
            }}
            button={
              <a
                className="link"
                href="https://www.instagram.com/p/CN-EPx2HJUG/?hl=en"
                target="_blank"
                rel="noreferrer noopener"
              >
                VIEW THE POST
              </a>
            }
          />
          <Card
            primary="THE TINDER BLOG"
            img={{
              src: require("./pictures/the_tinder_blog.jpg"),
              alt: "the tinder blog logo",
            }}
            button={
              <a
                className="link"
                href="https://www.instagram.com/p/CQ_jas3B0Oy/"
                target="_blank"
                rel="noreferrer noopener"
              >
                VIEW THE POST
              </a>
            }
          />
          <Accordion title="AND MORE">
            <a
              className="link"
              href="https://www.instagram.com/thedad/p/CyM3nY3uM-u/"
              target="_blank"
              rel="noreferrer noopener"
            >
              THE DAD
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/BsMK0zMAVqR/"
              target="_blank"
              rel="noreferrer noopener"
            >
              BUZZFEED (AGAIN)
            </a>
            <a
              className="link"
              href="https://www.yahoo.com/news/professor-helps-student-ask-crush-102620264.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              YAHOO
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/B5JPze4n-db/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @SHIP
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/C8WwgOcOMNT/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @FEAROFGOINGOUT
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/Br9I8g3gLDJ/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @NO.F*CKSGIIVEN
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/CdQ5v5nutzp/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @ASAFEPLACEINSIDEYOURHEAD
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/COn_Xg5jMyF/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @IAMTHIRTYAF
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/Bi5d5jzF3S7/?img_index=1"
              target="_blank"
              rel="noreferrer noopener"
            >
              @SELFHELPTHREADS
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/BcD-8PlHVm7/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @KALESALADQUOTES
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/C7J4Gp2L-U6/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @MOMMYWINETIME
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/CWUjj_MLpsk/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @THEBASICGAYDRUNK
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/COokK7-ry_f/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @GIRLSTHINKIMFUNNY
            </a>
            <a
              className="link"
              href="https://www.instagram.com/thedad/p/CyM3nY3uM-u/"
              target="_blank"
              rel="noreferrer noopener"
            >
              MY BIGGEST HATER
            </a>
            <p className="ctext">...and plenty more.</p>
          </Accordion>
        </div>

        <h2 style={{ marginTop: "4rem" }}>VIRAL CONTENT</h2>
        <Accordion open title="TWITTER">
          <Pictures
            pictures={[
              { src: require("./pictures/act.jpg"), alt: "d" },
              { src: require("./pictures/lip_gloss.jpg"), alt: "d" },
              { src: require("./pictures/math_partner.jpg"), alt: "d" },
              { src: require("./pictures/megan.jpg"), alt: "d" },
              { src: require("./pictures/oldest_kid.jpg"), alt: "d" },
              { src: require("./pictures/pocket_dollar.jpg"), alt: "d" },
              { src: require("./pictures/alexa.jpg"), alt: "d" },
              { src: require("./pictures/bella_from_twilight.jpg"), alt: "d" },
              { src: require("./pictures/dads_signature.jpg"), alt: "d" },
              { src: require("./pictures/dead_grandma.jpg"), alt: "d" },
              { src: require("./pictures/horse_divorce.jpg"), alt: "d" },
              { src: require("./pictures/lingerie.jpg"), alt: "d" },
            ]}
          />
        </Accordion>
        <Accordion title="TIKTOK">
          <p className="o64">
            i can't embed my tiktoks on my website without exposing the entire
            site to tiktok's "privacy" policy, and i'm not about to do that, so
            bear with me as i manually upload them...
          </p>
        </Accordion>

        <h2 style={{ marginTop: "4rem" }}>FOLLOW ME</h2>
        <a
          href="https://www.youtube.com/@squidslippers"
          target="_blank"
          rel="noreferrer noopener"
          className="link"
        >
          TIKTOK
        </a>
        <a
          href="https://www.youtube.com/@squidslippers"
          target="_blank"
          rel="noreferrer noopener"
          className="link"
        >
          YOUTUBE
        </a>
        <a
          href="https://www.linkedin.com/in/jacob-moreno/"
          target="_blank"
          rel="noreferrer noopener"
          className="link"
        >
          LINKEDIN
        </a>
        <a
          href="https://www.instagram.com/squidslippers/"
          target="_blank"
          rel="noreferrer noopener"
          className="link"
        >
          INSTAGRAM
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
