import React from "react";
import css from "./WireName.module.css";

const WireName = ({ name, onClick }) => {
  return (
    <div className={`${css.main}`}>
      <div
        className={`${css.wire} ${
          css[`wire_${name.specifics.color.replace(" ", "_")}`]
        }`}
      >
        <div className={css.delete} onClick={() => onClick(name.id)} />

        <p>
          <span className="o64">"</span>
          {name.specifics.word}
          <span className="o64">"</span>
          {name.specifics.adaptation && (
            <span className="o64" style={{ marginLeft: "1rem" }}>
              ({name.specifics.adaptation})
            </span>
          )}
        </p>
      </div>

      <p className={css.price}>${name.price}</p>
    </div>
  );
};

export default WireName;
