import React from "react";
import { Link } from "react-router-dom";
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";

const Art = () => {
  const wirenames = [
    {
      src: require("../wire_names/lucas.jpg"),
      alt: "The name Lucas made out of wire.",
    },
    {
      src: require("../wire_names/jess.jpg"),
      alt: "The name Jess made out of wire.",
    },
    {
      src: require("../wire_names/isaac.jpg"),
      alt: "The name Isaac made out of wire.",
    },
    {
      src: require("../wire_names/ed.jpg"),
      alt: "The name Ed made out of wire.",
    },
    {
      src: require("../wire_names/billy.jpg"),
      alt: "The name Billy made out of wire.",
    },
    {
      src: require("../wire_names/bubbles.jpg"),
      alt: "The name Bubbles made out of wire.",
    },
    {
      src: require("../wire_names/joy.jpg"),
      alt: "The name Joy made out of wire.",
    },
    {
      src: require("../wire_names/ernesto.jpg"),
      alt: "The name Ernesto made out of wire.",
    },
    {
      src: require("../wire_names/jacob.jpg"),
      alt: "The name Jacob made out of wire.",
    },
    {
      src: require("../wire_names/kaz.jpg"),
      alt: "The name Kaz made out of wire.",
    },
    {
      src: require("../wire_names/jason.jpg"),
      alt: "The name Jason made out of wire.",
    },
  ];

  const woodburnings = [
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/potter.webp",
      alt: "harry potter and voldemort",
      description:
        "i made this for fun and gave it away to a stranger on the internet.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
      alt: "a husband and wife looking at each other",
      description:
        "a friend of mine commissioned this wood burning as a christmas gift.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/halo.webp",
      alt: "master chief",
      description: "this commission was one of my earliest wood burnings.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/jesus.webp",
      alt: "jesus",
      description: "i made this as a gift for a family member.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/elsa.webp",
      alt: "elsa",
      description:
        "i made this for fun and gave it away to a stranger on the internet.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/art/cover.webp",
      alt: "wedding guest book cover",
      description: "a wedding guest book cover a friend commissioned from me.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/gaten.webp",
      alt: "gaten matarazzo",
      description:
        "shoutout to when 'stranger things' was big and i made this.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/capone.webp",
      alt: "capone",
      description: "a wood burning i made to honor my friend's late dog.",
    },
  ];

  const logos = [
    {
      src: "https://storage.googleapis.com/squidslippers-media/logo/hackpack.webp",
      alt: "an abstract infinity symbol with an 'H' hidden inside.",
      description:
        "the hackpack logo. 'one pack. infinite possibilities.' one of my all-time favorites! my intention was to include an abstract infinity symbol with an 'h' hidden in the negative space.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/logo/cm.webp",
      alt: "cloud money",
      description:
        "the cloud money logo. 'the art of financial freedom.' managing money is difficult when your income fluctuates. cloud money makes it easy to budget with an inconsistent income!",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/logo/mxm.webp",
      alt: "m with a circle over it",
      description:
        "the mantle logo. 'every experience matters. make them count.' i designed this to represent the 'core' of business success (customer experience), and i hoped the subtle imagery comes across.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/logo/releafer.webp",
      alt: "three leaves sprouting from a center point",
      description:
        "the releafer logo. 'help is here.' a subtle homage to the recycling symbol, meant to represent the 'refilling' of medical cannabis cartridges.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/logo/ccc.webp",
      alt: "a sideways crystal.",
      description: "the crystal clear consulting logo.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/logo/ss.jpg",
      alt: "a white squid-shaped creature with two 's'-shaped legs against a dark blue and purple background.",
      description: "the squidslippers logo",
    },
  ];

  const claysculptures = [
    {
      src: "https://storage.googleapis.com/squidslippers-media/clay/jigglypuff.webp",
      alt: "jigglypuff",
      description:
        "a jigglypuff sculpture i made out of polymer clay and acryllic paint. my dogs got to her, which is why she's a little beaten up. but she's still adorable.",
      right: true,
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/clay/bobomb.webp",
      alt: "bob-omb",
      description:
        "i made this bob-omb before i knew you could bake polymer clay more than once, so it's actually all made out of one piece. if i ever make another one, i'll definitely make it one piece at a time, and it'll look a lot better.",
      right: true,
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/clay/shrek.webp",
      alt: "shrek",
      description:
        "this is the first and only face i've made out of clay. it's not perfect, but honestly i think i nailed it, especially for my first try!",
      right: true,
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/clay/earrings.webp",
      alt: "earrings",
      description:
        "some elements of harmony earrings i made for emma. they didn't turn out so bad... but they didn't turn out nearly how i wanted.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/clay/diglett.webp",
      alt: "diglett",
      description:
        "a diglett sculpture i made out of polymer clay and acryllic paint. it's a plant pot accessory: you bury the flat base in soil, then you have a little diglett living in your plant! how fun!",
      right: true,
    },
  ];

  const miscellaneous = [
    {
      src: "https://storage.googleapis.com/squidslippers-media/art/squidward.webp",
      alt: "squidward",
      description:
        '"bold and brash" or "belongs in the trash"? (real ones know.)',
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/art/pig.webp",
      alt: "pig",
      description:
        "an acrylic painting i made as a commission. i'm not a huge fan of painting.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/art/baylee.webp",
      alt: "baylee",
      description: "a drawing i made for mrs. utah america herself.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/art/olaf.webp",
      alt: "olaf",
      description:
        "yeah, that's an actual snowman! it's actually one of several olaf snowmen i've biult over the years. the trick is to build a triangle of snow behind his body to support his head! otherwise, it doesn't last long at ALL. but this lasted a good while!",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/art/egg.webp",
      alt: "egg",
      description: "a hyper-realistic egg i drew using procreate.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/art/pearl.webp",
      alt: "pearl",
      description: "a hyper-realistic pearl i drew using procreate.",
    },
  ];

  return (
    <div className="main">
      <Intro
        primary="I 🤍 ART"
        secondary="i make, perform, sell, and give away all kinds of art: wood burning, logo design, digital art, painting, sculpting, drawing, and more—you name it, i've (probably) tried it! if you want to browse my full portfolio and buy some art..."
        tertiary="you're in the right place."
      />

      <div className="column p01">
        <h2>WIRE NAMES</h2>
        <Pictures pictures={wirenames} />
        <Link className="link" to={"/wire-names"}>
          GET WIRE NAMES
        </Link>

        <h2 style={{ marginTop: "4rem" }}>WOOD BURNING</h2>
        <Pictures pictures={woodburnings} />
        <Link className="link" to={"/wood-burning"}>
          GET A WOOD BURNING
        </Link>

        <h2 style={{ marginTop: "4rem" }}>LOGO + GRAPHIC DESIGN</h2>
        <Pictures pictures={logos} />

        <h2 style={{ marginTop: "4rem" }}>CLAY</h2>
        <Pictures pictures={claysculptures} />

        <h2 style={{ marginTop: "4rem" }}>OTHER</h2>
        <Pictures pictures={miscellaneous} />
      </div>
    </div>
  );
};

export default Art;
