import React, { useContext, useState } from "react";
import css from "./ColoredPaincils.module.css";
import Card from "../../components/card/Card";
import { Link } from "react-router-dom";
import Form from "./Form";

import Pictures from "../../components/pictures/Pictures";
import Accordion from "../../components/accordion/Accordion";
import CartContext from "../../context/cart";
import ColoredPaincil from "../../components/coloredpaincil/ColoredPaincil";
import Small from "../../components/intro/Small";

const ColoredPaincils = () => {
  const { cart, removeFromCart } = useContext(CartContext);
  const [form, setForm] = useState(false);

  const toggleForm = () => {
    setForm((old) => !old);
  };

  let paincils = cart.filter((item) => item.title === "colored paincil");

  return (
    <div className="main">
      {form && <Form close={() => setForm(false)} />}
      <Small primary="COLORED PAINCILS" secondary="FUN FOR EVERYBODY">
        <button
          style={{ marginTop: "1rem", maxWidth: "32rem" }}
          className="button"
          onClick={toggleForm}
        >
          GET ONE
        </button>
      </Small>
      {/* <div className={css.intro}> */}
      <img
        className={css.img}
        src={
          "https://storage.googleapis.com/squidslippers-media/colored_paincils/background.png"
        }
      />
      {/* </div> */}
      <div className={css.background}>
        <div className="column p01">
          <p className="o64 ctext">
            the colored paincil is a branch of strong wood (oak) that i carve
            and paint to look like a larger-than-life colored pencil. it looks
            fun and playful, but this self-defense device packs quite a punch!
            check out some of the things that make it special:
          </p>
          <div className="flex wrap">
            <Card primary="SAFER PUNCHES" />
            <Card primary="24/7 PROTECTION" />
            <Card primary="MUCH STRONGER STRIKES" />
            <Card primary="CUTER ACCESSORIES" />
            <Card primary="LIFETIME WARRANTY" />
            <Card primary="PERFECT HOLIDAY GIFT" />
          </div>
          <button className="button" onClick={toggleForm}>
            GET ONE
          </button>
        </div>
      </div>
      <div className="column p01">
        {paincils.length > 0 && (
          <>
            <h2 style={{ marginTop: "4rem" }}>YOUR PAINCILS</h2>
            <ul className={css.paincils}>
              {paincils.map((item) => (
                <ColoredPaincil paincil={item} onClick={removeFromCart} />
              ))}
            </ul>
            <Link className="button" to="/checkout">
              CONTINUE TO CHECKOUT
            </Link>
          </>
        )}
        <h2 style={{ marginTop: "4rem" }}>YOUR QUESTIONS ANSWERED</h2>
        {/* <Pictures
          pictures={[
            {
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/line.webp",
              alt: "colored pencils falling down a white background.",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/descending.webp",
              alt: "giant colored pencils in a line. they're side-by-side, directly adjacent, with the smallest on the left and the largest on the right. from left to right, they're in this order: black, purple, blue, green, yellow, orange, red, pink, white",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/falling.webp",
              alt: "colored pencils falling down a white background.",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/name.webp",
              alt: "colored pencils falling down a white background.",
            },
          ]}
        /> */}
        <Accordion title="HOW BIG IS IT?">
          <p className="o64">
            right now, they only come in one size: 1" diameter and 6" long (from
            base to tip).
          </p>
        </Accordion>
        <Accordion title="CAN I WRITE WITH IT?">
          <p className="o64">
            no. it doesn't color like a real colored pencil—but that's a good
            thing, as it means it won't get dull over time.
          </p>
        </Accordion>
        <Accordion title="DOES IT ACTUALLY HURT?">
          <p className="o64">
            yes! oak is a hard wood, and i sharpen every colored paincil to a
            fine point. this is, essentially, a wooden stake—the same thing they
            used to bring your favorite vampire's story to a bitter end.
          </p>
          <p className="o64">
            on a serious note, it does make punches stronger by providing
            additional support to your fists, and striking someone with the
            point will do some serious damage.
          </p>
        </Accordion>
        <Accordion title="CAN I TAKE IT ANYWHERE?">
          <p className="o64">yes! so far, i've never been stopped with one.</p>
        </Accordion>
        <button onClick={toggleForm} className="button">
          GET ONE
        </button>

        <h2 style={{ marginTop: "4rem" }}>MORE TO SHOP</h2>
        <div className="flex wrap">
          <Card
            primary="WIRE NAMES"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/art/wire/falling.png",
              alt: "wire names scattered across a black background",
            }}
            button={
              <Link className="link" to="/wire-names">
                GET WIRE NAMES
              </Link>
            }
          />
          <Card
            primary="WOOD BURNING"
            button={
              <Link className="link" to="/wood-burning">
                GET A WOOD BURNING
              </Link>
            }
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ColoredPaincils;
