import React from "react";
import css from "./LI.module.css";
import _check from "./check.png";

const LI = ({ children, type }) => {
  let check;

  switch (type) {
    case "none":
      check = <div className={css.img} />;
      break;
    case "unchecked":
      check = <div className={css.unchecked} />;
      break;
    default: {
      check = <img src={_check} alt="check" className={css.img} />;
    }
  }

  return (
    <li className={css.li}>
      <div className={css.container}>{check}</div>
      {children}
    </li>
  );
};

export default LI;
