import React, { useContext, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import "./App.css";
import NotificationsContext from "./context/notifications";
import IAMContext from "./context/iam";

import Header from "./components/header/Header";
import Cart from "./components/cart/Cart";
import Footer from "./components/footer/Footer";

import CartPage from "./pages/cart/Cart";
import Manage from "./pages/admin/live/Manage";
import View from "./pages/admin/live/View";
import Login from "./pages/admin/login/Login";

import Dashboard from "./pages/home/Dashboard";
import Home from "./pages/home/Home";

import Art from "./pages/art/Art";
import AnimalCrossing from "./pages/video_games/AnimalCrossing";
import Checkout from "./pages/checkout/Checkout";
import ColoredPaincils from "./pages/colored_paincils/ColoredPaincils";
import ContentAndDesign from "./pages/content_and_design/ContentAndDesign";
import Photography from "./pages/photography/Photography";
import PrivacyPolicy from "./pages/privacy_policy/PrivacyPolicy";
import Order from "./pages/orders/order/Order";
import Shop from "./pages/shop/Shop";
import SocialMedia from "./pages/social_media/SocialMedia";
import VideoGames from "./pages/video_games/VideoGames";
import WebsiteDevelopment from "./pages/software_and_websites/SoftwareAndWebsites";
import WireNames from "./pages/wire_names/WireNames";
import NameOfTheDay from "./pages/wire_names/NameOfTheDay";
import WoodBurning from "./pages/wood_burning/WoodBurning";

// VIRTUAL COOKBOOK
import BrusselsSproutsAndBacon from "./pages/cookbook/recipes/BrusselsSproutsAndBacon";
import EggDropSoup from "./pages/cookbook/recipes/EggDropSoup";
import Cookbook from "./pages/cookbook/Cookbook";
import IncineratedToast from "./pages/cookbook/recipes/IncineratedToast";
import Pancakes from "./pages/cookbook/recipes/Pancakes";

import DarkMatter from "./pages/dark_matter/DarkMatter";
import PreLaunch from "./pages/resources/PreLaunch";

function App() {
  const { array } = useContext(NotificationsContext);
  const { token } = useContext(IAMContext);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  let adminLogin, adminDashboard, manageLive, viewLive;

  if (token) {
    adminDashboard = <Dashboard />;
    adminLogin = <Navigate replace to="/admin/dashboard" />;
    manageLive = <Manage />;
    viewLive = <View />;
  } else {
    adminDashboard = <Navigate replace to="/admin/login" />;
    adminLogin = <Login />;
    manageLive = <Navigate replace to="/admin/login" />;
    viewLive = <Navigate replace to="/admin/login" />;
  }

  return (
    <div className="App">
      {array}
      <Header />
      <Cart />

      <Routes>
        <Route exact path="/" element={<Home />} />

        {/* ADMIN */}
        <Route path="/admin/dashboard" element={adminDashboard} />
        <Route path="/admin/live/manage" element={manageLive} />
        <Route path="/admin/live/view" element={viewLive} />
        <Route path="/admin/login" element={adminLogin} />

        {/* SHOP */}
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/colored-paincils" element={<ColoredPaincils />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/order/:id" element={<Order />} />
        <Route path="/name-of-the-day" element={<NameOfTheDay />} />
        <Route path="/wire-names" element={<WireNames />} />
        <Route path="/wood-burning" element={<WoodBurning />} />

        {/* COLLABORATE */}
        <Route path="/social-media" element={<SocialMedia />} />
        <Route path="/software-and-websites" element={<WebsiteDevelopment />} />
        <Route path="/content-and-design" element={<ContentAndDesign />} />

        <Route path="/clients/dark-matter-concepts" element={<DarkMatter />} />

        {/* RESOURCES */}
        <Route
          path="/recipe/brussels-sprouts-and-bacon"
          element={<BrusselsSproutsAndBacon />}
        />
        <Route path="/recipe/egg-drop-soup" element={<EggDropSoup />} />
        <Route
          path="/recipe/incinerated-toast"
          element={<IncineratedToast />}
        />
        <Route path="/recipe/pancakes" element={<Pancakes />} />
        <Route path="/pre-launch-guide" element={<PreLaunch />} />

        {/* ME */}
        <Route path="/art" element={<Art />} />
        <Route path="/food" element={<Cookbook />} />
        <Route path="/photography" element={<Photography />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/video-games" element={<VideoGames />} />
        <Route
          path="/video-games/animal-crossing"
          element={<AnimalCrossing />}
        />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
