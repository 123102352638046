import React from "react";
import { Link } from "react-router-dom";
// COMPONENTS
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";
import Accordion from "../../components/accordion/Accordion";
// ASSETS
import clouds1 from "../photography/pictures/clouds1.jpg";
import parents from "../photography/pictures/parents.jpg";
import Card from "../../components/card/Card";

const Home = () => {
  return (
    <div className="main">
      <Intro
        primary="WELCOME!"
        secondary="if you want to learn about art, comedy, eevee, maple, music, photography, psychology, social media, software engineering, storytelling, video games, website development, writing, and other things i love..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>SHOP</h2>
        <div className="flex wrap">
          <Card
            primary="WIRE NAMES"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/art/wire/falling.png",
              alt: "wire names scattered across a black background",
            }}
            button={
              <Link className="link" to="/wire-names">
                GET SOME
              </Link>
            }
          />

          <Card
            primary="COLORED PAINCILS"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
              alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
            }}
            button={
              <Link className="link" to="/colored-paincils">
                GET ONE
              </Link>
            }
          />

          <Card
            primary="WOOD BURNING"
            button={
              <Link className="link" to="/wood-burning">
                GET ONE
              </Link>
            }
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
            }}
          />
        </div>

        <h2 style={{ marginTop: "4rem" }}>COLLABORATE</h2>

        <Accordion title="SOFTWARE + WEBSITES">
          <p className="o64">
            10+ years of experience, 2,150,000+ users served! i can help you
            design, build, maintain, or improve any software or website.
          </p>
          <Link className="link" to="/software-and-websites">
            LEARN MORE
          </Link>
        </Accordion>
        <Accordion title="CONTENT + DESIGN">
          <p className="o64">
            professional photography, videography, copywriting, user-generated
            content, graphic design, ux/ui design, and more!
          </p>
          <Link className="link" to="/content-and-design">
            LEARN MORE
          </Link>
        </Accordion>

        <h2 style={{ marginTop: "4rem" }}>RESOURCES</h2>
        <div className="flex wrap">
          <Card
            primary="VIRTUAL COOKBOOK"
            secondary="with an interactive step-by-step guide for several recipes!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/food/brussels_sprouts_bacon.webp",
              alt: "brussels sprouts and bacon in a pan",
            }}
            button={
              <Link className="link" to={"/food"}>
                MAKE SOME FOOD
              </Link>
            }
          />
          <Card
            primary="CLOUD MONEY"
            secondary="finally, a budgeting tool for inconsistent incomes!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/cm2x1.jpg",
              alt: "cloud money logo",
            }}
            button={
              <a
                className="link"
                href="https://cloudmoney.wm.r.appspot.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                CHECK IT OUT
              </a>
            }
          />
        </div>

        <h2 style={{ marginTop: "4rem" }}>ME</h2>

        <Accordion title="ART">
          <p className="o64">
            i make, perform, sell, and give away all kinds of art: wood burning,
            logo design, digital art, painting, sculpting, drawing, and more—you
            name it, i've (probably) tried it!
          </p>
          <Pictures
            key={1}
            pictures={[
              {
                src: "https://storage.googleapis.com/squidslippers-media/wood_burning/potter.webp",
                alt: "a husband and wife looking at each other",
                description:
                  "a friend of mine commissioned this wood burning as a christmas gift for his wife.",
              },
              {
                src: "https://storage.googleapis.com/squidslippers-media/art/egg.webp",
                alt: "egg",
                description: "a hyper-realistic egg i drew using procreate.",
              },
              {
                src: "https://storage.googleapis.com/squidslippers-media/squidslippers.webp",
                alt: "squidslippers",
                description: "my squidslippers logo! i love this little guy!",
              },
              {
                src: "https://storage.googleapis.com/squidslippers-media/art/pig.webp",
                alt: "pig",
                description: "an acrylic painting i made as a commission.",
              },
            ]}
          />
          <Link className="link" to={"/art"}>
            EXPLORE MY ART
          </Link>
        </Accordion>

        <Accordion title="PHOTOGRAPHY">
          <p className="o64">
            i'm not a professional or anything, but i do have a nice camera, a
            basic understanding of photography, a lot of free time, and a few
            neat pictures to show for it.
          </p>
          <Pictures
            key={2}
            pictures={[
              {
                src: "https://storage.googleapis.com/squidslippers-media/nature/raja.webp",
                alt: "red, white, and blue betta fish",
                description:
                  "anika and i love betta fish. everyone, this is raja. raja, everyone.",
              },
              {
                src: "https://storage.googleapis.com/squidslippers-media/lifestyle/feet.webp",
                alt: "feet prints in sand",
                description:
                  "getting a picture like this is NOT easy, i'll tell you that much.",
              },
              {
                src: clouds1,
                alt: "clouds",
                description:
                  "(the sky is the artist here; i just took the picture.)",
              },
              {
                src: parents,
                alt: "parents",
                description:
                  "my mom and dad are so cute and great! just look at 'em!",
              },
            ]}
          />
          <Link className="link" to={"/photography"}>
            EXPLORE MY PHOTOGRAPHY
          </Link>
        </Accordion>

        <Accordion title="SOCIAL MEDIA">
          <p className="o64">
            my content has gone viral on just about every major platform, with
            over 300,000,000 combined views. i've been featured on major
            platforms like buzzfeed, f***jerry...
          </p>
          <Link className="link" to={"/social-media"}>
            EXPLORE MY SOCIAL MEDIA
          </Link>
        </Accordion>
      </div>
    </div>
  );
};

export default Home;
