import React, { useContext } from "react";
import { Link } from "react-router-dom";
import css from "../Food.module.css";
import Intro from "../../../components/intro/Intro";
import Step from "../Step";
import NotificationsContext from "../../../context/notifications";
import Card from "../../../components/card/Card";
import Pancakes from "../previews/Pancakes";
import BrusselsSproutsAndBacon from "../previews/BrusselsSproutsAndBacon";

const EggDropSoup = () => {
  const { addNotification } = useContext(NotificationsContext);
  return (
    <div className="main">
      <Intro
        primary="EGG DROP SOUP"
        secondary="this recipe makes enough for 2 adults to have a warm, satisfying meal. it's fast and easy: you prepare and add the ingredients while it's cooking. (don't worry, you'll have plenty of time even if you're slow like me.)"
      />
      <div className="column p01">
        <h2>SHOP IT</h2>
        <div className="columns">
          <div className={css.instruction}>
            <strong>INGREDIENTS</strong>
            <Step text="stock ( chicken / beef )" right="4 cups" />
            <Step text="sesame oil" right="~1 tablespoon" />
            <Step text="soy sauce" right="~1 tablespoon" />
            <Step text="eggs" right="4" />
            <Step text="cilantro" right="~1/4 cup" />
            <Step text="salt" right="to taste" />
            <Step text="pepper" right="to taste" />
          </div>
          <div className={css.instruction}>
            <strong>TOOLS</strong>
            <Step text="stove" right="1" />
            <Step text="pot" right="1" />
            <Step text="ladle" right="1" />
            <Step text="bowl" right="1" />
            <p className="ctext o64">
              the rest of the tools are optional: knife and cutting board can be
              replaced by something else like scissors (i just rip it with my
              hands), and it's okay to eyeball the rest of the ingredients.
            </p>
            <Step text="cutting board" right="1" />
            <Step text="knife" right="1" />
            <Step text="1 cup" right="1" />
            <Step text="1/4 cup" right="1" />
            <Step text="tablespoon" right="1" />
          </div>
        </div>
        <h2 style={{ marginTop: "4rem" }}>MAKE IT</h2>
        <div className={css.instruction}>
          <Step text="add the stock to the pot." />
          <Step text="add the sesame oil to the pot." />
          <Step text="add the soy sauce to the pot." />
        </div>
        <div className={css.instruction}>
          <Step text="put the pot on the stove and turn the heat to medium-low." />
        </div>
        <p className="ctext">let the stock heat until it's at a slow bubble.</p>
        <p className="o64 ctext">while the stock is heating...</p>
        <div className={css.instruction}>
          <Step text="add the eggs to the bowl—NOT the pot!" />
        </div>
        <div className={css.instruction}>
          <Step text="use the ladle to beat the eggs until just combined." />
        </div>
        <div className={css.instruction}>
          <Step text="mince the cilantro." />
        </div>
        <p className="o64 ctext">once the stock is hot...</p>
        <div className={css.instruction}>
          <Step text="add the eggs to the soup." />
          <p className="o64 ctext">
            slowly pour the eggs into the soup with one hand while using the
            ladle to stir the soup with your other hand. consistent movement +
            low heat = velvety ribbons of egg (which is what you're going for).
          </p>
        </div>
        <div className={css.instruction}>
          <Step text="remove the pot from the heat." />
          <Step text="add the cilantro to the pot." />
        </div>
        <button
          className="button"
          onClick={() =>
            addNotification({
              primary: "YOU DID IT!",
              secondary: "nice work!",
              type: "success",
              showTime: 4,
            })
          }
        >
          CELEBRATE!
        </button>
      </div>
      <h2 style={{ marginTop: "4rem" }}>SERVE IT</h2>
      <div className="flex wrap">
        <Card primary="HOT, HOT, HOT!" />
        <Card primary="BOWL" />
        <Card primary="SPOON" />
      </div>
      <h2 style={{ marginTop: "4rem" }}>MORE MEALS TO TRY</h2>
      <div className="flex wrap">
        <BrusselsSproutsAndBacon />
        <Pancakes />
      </div>
    </div>
  );
};

export default EggDropSoup;
