import React from "react";
import Card from "../../../components/card/Card";
import { Link } from "react-router-dom";

const BrusselsSproutsAndBacon = () => {
  return (
    <Card
      primary="BRUSSELS SPROUTS + BACON"
      img={{
        src: "https://storage.googleapis.com/squidslippers-media/food/brussels_sprouts_bacon.webp",
        alt: "brussels sprouts and bacon",
      }}
      button={
        <Link className="link" to="/recipe/brussels-sprouts-and-bacon">
          TRY IT
        </Link>
      }
    />
  );
};

export default BrusselsSproutsAndBacon;
