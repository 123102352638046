import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import css from "../Food.module.css";
import Intro from "../../../components/intro/Intro";
import Step from "../Step";
import NotificationsContext from "../../../context/notifications";
import Card from "../../../components/card/Card";
import Radio from "../../../components/radio/Radio";
import BrusselsSproutsAndBacon from "../previews/BrusselsSproutsAndBacon";
import EggDropSoup from "../previews/EggDropSoup";
import Timer from "../Timer";

const Pancakes = () => {
  const [type, setType] = useState("plain");
  const { addNotification } = useContext(NotificationsContext);

  const changeType = (type) => {
    setType(type);
  };

  return (
    <div className="main">
      <Intro
        primary="PANCAKES"
        secondary="this cooks 3 large, light, fluffy, fast, easy, simple, versatile, delicious pancakes (we find it's enough to share for breakfast). if you want to make pancake mix, pre-mix the dry ingredients beforehand and store, then add the milk and eggs when you're in the mood."
        pictures={[
          {
            src: "https://storage.googleapis.com/squidslippers-media/food/pancakes.webp",
            alt: "pancake",
          },
        ]}
      />
      <div className="column p01" style={{ position: "relative" }}>
        <h2>CUSTOMIZE IT</h2>
        <p className="o64 ctext">
          before we dive into the recipe, you'll need to decide what kind of
          pancakes you'd like to make. the instructions will adapt to whichever
          option you choose!
        </p>
        <Radio
          title="CHOOSE YOUR TYPE"
          onChange={changeType}
          options={[
            { display: "plain pancakes", value: "plain" },
            { display: "banana pancakes", value: "banana" },
            { display: "blueberry pancakes", value: "blueberry" },
          ]}
          selected={type}
        />

        <h2 style={{ marginTop: "4rem" }}>SHOP IT</h2>
        <div className="columns">
          <div className={css.instruction}>
            <strong>INGREDIENTS</strong>
            <Step text="eggs" right="1" />
            <Step text="milk" right="1 cup" />
            <Step text="flour (all-purpose)" right="1 cup" />
            <Step text="baking powder" right="1 teaspoon" />
            <Step text="salt" right="1 pinch" />
            <Step text="sugar" right="1/2 tablespoon" />
            {type === "banana" && <Step text="banana" right="1" />}
            {type === "blueberry" && <Step text="blueberries" right="~21" />}
            {/* {type === "ccc" && <Step text="coconut" right="" />} */}
          </div>
          <div className={css.instruction}>
            <strong>TOOLS</strong>
            <Step text="stove" right="1" />
            <Step text="pan" right="1" />
            <Step text="spatula" right="1" />
            <Step text="bowl (large)" right="1" />
            <Step text="1 teaspoon" right="1" />
            <Step text="1/2 tablespoon" right="1" />
            <Step text="1 cup" right="1" />
            {["banana", "blueberry"].includes(type) && (
              <Step text="knife" right="1" />
            )}
          </div>
        </div>
        <h2 style={{ marginTop: "4rem" }}>MAKE IT</h2>
        <div className={css.instruction}>
          <Step text="add the milk to the bowl." />
          <Step text="add the egg to the bowl." />
        </div>
        <div className={css.instruction}>
          <Step text="gently mix the milk and egg together until they are just combined." />
        </div>
        <div className={css.instruction}>
          <Step text="add the salt to the bowl." />
          <Step text="add the sugar to the bowl." />
          <Step text="add the baking powder to the bowl." />
          <Step text="add the flour to the bowl." />
        </div>
        <div className={css.instruction}>
          <Step text="gently mix the dry ingredients only enough to moisten the dry ingredients." />
          <p className="ctext o64">
            slowly folding the dry ingredients into the wet ones is a great way
            to combine them. lumps are totally okay—and so is breaking them up!
          </p>
        </div>
        <div className={css.instruction}>
          <Step text="put the pan on the stove and set the heat to medium-low." />
        </div>
        <Timer
          primary={
            <p className="ctext">let the pan heat up and the mixture settle.</p>
          }
          initialMinutes={2}
        />
        {type === "banana" && (
          <>
            <p className="o64 ctext">while the pan is heating up...</p>
            <div className={css.instruction}>
              <Step text="slice the banana into ~21 slices." />
              <p className="o64 ctext">
                i like my pancakes with exactly 7 banana slices, with one in the
                middle and six along the outside.
              </p>
              <p className="o64 ctext">
                banana has a strong taste, and it's only intensified when the
                outside is caramelized by the heat, so slice them thinly—or
                don't! who cares!
              </p>
            </div>
            <p className="o64 ctext">once the pan is hot...</p>
          </>
        )}
        {type === "blueberry" && (
          <>
            <p className="o64 ctext">while the pan is heating up...</p>
            <div className={css.instruction}>
              <Step text="slice the blueberries in half." />
              <p className="o64 ctext">
                i like my pancakes with ~7 blueberries (~14 blueberry halves)
                scattered throughout.
              </p>
            </div>
            <p className="o64 ctext">once the pan is hot...</p>
          </>
        )}
        <div className={css.instruction}>
          <Step text="add 3/4 cup of batter to the pan." />
        </div>
        {type === "banana" && (
          <>
            <div className={css.instruction}>
              <Step text="add the banana slices to the batter." />
              <p className="ctext o64">
                you can go slowly, but you want to start adding them immediately
                after you add the batter.
              </p>
            </div>
            <div className={css.instruction}>
              <Step text="press the banana slices into the batter using the spatula." />
            </div>
          </>
        )}
        {type === "blueberry" && (
          <>
            <div className={css.instruction}>
              <Step text="add the blueberry halves to the batter." />
              <p className="ctext o64">
                you can go slowly, but you want to start adding them immediately
                after you add the batter.
              </p>
            </div>
            <div className={css.instruction}>
              <Step text="press the blueberry halves into the batter using the spatula." />
            </div>
          </>
        )}
        <Timer
          primary={
            <p className="ctext">
              let the pancake cook until the bottom is firm.
            </p>
          }
          initialMinutes={2}
        />
        <div className={css.instruction}>
          <Step text="flip the pancake over." />
        </div>
        <Timer
          primary={
            <p className="ctext">
              let the pancake cook until it is cooked through.
            </p>
          }
          initialMinutes={2}
        />
        <button
          className="button"
          onClick={() =>
            addNotification({
              primary: "YOU DID IT!",
              secondary: "nice work!",
              type: "success",
              showTime: 4,
            })
          }
        >
          CELEBRATE!
        </button>
        <h2 style={{ marginTop: "4rem" }}>SERVE IT</h2>
        <div className="flex wrap">
          <Card primary="HOT, HOT, HOT!" />
          <Card primary="PLATE" />
          <Card primary="FORK" />
        </div>
        <p className="o64">
          top them with butter, syrup, jelly, peanut butter (trust me), or
          whatever else you'd like. (if you have any good ideas, let me know—i'd
          love to try them).
        </p>
        <h2 style={{ marginTop: "4rem" }}>MORE MEALS TO TRY</h2>
        <div className="flex wrap">
          <BrusselsSproutsAndBacon />
          <EggDropSoup />
        </div>
      </div>
    </div>
  );
};

export default Pancakes;
