import React, { useState } from "react";
import css from "./Food.module.css";
import check from "./check.png";

const Step = ({ text, right }) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className={css.step} onClick={() => setChecked((old) => !old)}>
      <button className={`${css.checkbox} ${checked ? css.checked : ""}`}>
        {checked && (
          <img src={check} alt="a check mark" className={css.check} />
        )}
      </button>
      <p>{text}</p>
      {right && <p className={css.right}>{right}</p>}
    </div>
  );
};

export default Step;
