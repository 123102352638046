import React, { useContext, useRef, useState } from "react";
import { useHttp } from "../../helpers/useHttp";
import css from "./WireNames.module.css";
import CartContext from "../../context/cart";
import NotificationsContext from "../../context/notifications";
import { Link } from "react-router-dom";
import Checkbox from "../../components/checkbox/Checkbox";
import WireName from "../../components/wirename/WireName";

const Form = () => {
  const { sendRequest } = useHttp();
  const { cart, addToCart, removeFromCart } = useContext(CartContext);
  const { addNotification } = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);
  const [word, setWord] = useState("");
  const [color, setColor] = useState("");
  const [adaptation, setAdaptation] = useState("");

  const inputRef = useRef();

  const addName = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      validate();

      let savedEntry = await sendRequest(
        "cart/items",
        "POST",
        JSON.stringify({
          title: "custom wire name",
          specifics: {
            color,
            word,
            adaptation,
          },
        })
      );

      addToCart(savedEntry);
      setWord("");
      inputRef.current.focus();
    } catch (error) {}
    setLoading(false);
  };

  function validate() {
    let notes = [];

    if (!/^[A-Za-z .]+$/.test(word)) {
      notes.push("word must only include letters and spaces.");
    }

    if (!["gold", "rose gold", "silver"].includes(color)) {
      notes.push("color must be gold, rose gold, or silver.");
    }

    if (notes.length > 0) {
      addNotification({
        primary: "INVALID INPUTS",
        secondary: notes.join(" "),
        type: "error",
        seconds: 5,
      });

      throw {};
    }
  }

  const changeColor = (color) => {
    setColor(color);
  };

  const changeWord = (e) => {
    setWord(e.target.value);
  };

  const changeAdaptation = (adaptation) => {
    setAdaptation((old) => {
      if (adaptation === old) return null;
      else return adaptation;
    });
  };

  let disabled = loading || word?.length === 0 || color?.length === 0;
  let names = cart.filter((item) => item.title === "custom wire name");

  return (
    <form onSubmit={addName}>
      <input
        className={css.input}
        placeholder="YOUR NAME HERE"
        autoFocus={true}
        onChange={changeWord}
        value={word}
        ref={inputRef}
      />

      <fieldset className="card">
        <h3 className="ctext">CHOOSE YOUR COLOR</h3>
        <div className="columns3">
          <button
            type="button"
            onClick={() => changeColor("gold")}
            className={`${css.color} ${!color || color === "gold" ? "" : "o4"}`}
          >
            GOLD
          </button>
          <button
            type="button"
            onClick={() => changeColor("rose gold")}
            className={`${css.color} ${
              !color || color === "rose gold" ? "" : "o4"
            }`}
          >
            ROSE GOLD
          </button>
          <button
            type="button"
            onClick={() => changeColor("silver")}
            className={`${css.color} ${
              !color || color === "silver" ? "" : "o4"
            }`}
          >
            SILVER
          </button>
        </div>
      </fieldset>

      <fieldset className="card">
        <h3 className="ctext">MAKE IT A ...</h3>
        <Checkbox
          onClick={() => changeAdaptation("keychain")}
          primary="keychain"
          checked={adaptation === "keychain"}
        />
        <Checkbox
          onClick={() => changeAdaptation("necklace")}
          primary="necklace"
          checked={adaptation === "necklace"}
        />
      </fieldset>

      <button
        className={`button ${disabled ? "disabled" : ""}`}
        type="submit"
        style={{ marginTop: 0 }}
        disabled={disabled}
      >
        {loading ? "loading..." : "ADD TO CART"}
      </button>

      {names.length > 0 && (
        <>
          <h2 style={{ marginTop: "4rem" }}>
            YOUR NAMES
          </h2>
          <ul className={css.names}>
            {names.map((item, index) => (
              <WireName key={index} name={item} onClick={removeFromCart} />
            ))}
          </ul>
          <Link className="button" to="/checkout">
            CONTINUE TO CHECKOUT
          </Link>
        </>
      )}
    </form>
  );
};

export default Form;
