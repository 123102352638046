import React, { useContext, useState } from "react";
import Intro from "../../components/intro/Intro";
import Input from "../../components/input/Input";
import NotificationsContext from "../../context/notifications";
import Card from "../../components/card/Card";
import css from "./DarkMatter.module.css";
import Accordion from "../../components/accordion/Accordion";
import Step from "../cookbook/Step";
import LI from "../../components/LI/LI";

const DarkMatter = () => {
  const _loggedIn = sessionStorage.getItem("ss_dark-matter");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loggedIn, setLoggedIn] = useState(_loggedIn);
  const { addNotification } = useContext(NotificationsContext);

  const changePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const submit = (e) => {
    e.preventDefault();
    if (password !== "$Hillcrest123") {
      setPassword("");
      setPasswordError("Enter a valid password.");
      addNotification({
        primary: "INCORRECT PASSWORD.",
        secondary: "Try again.",
        type: "error",
      });
    } else {
      setLoggedIn(true);
      sessionStorage.setItem("ss_dark-matter", true);
      addNotification({
        primary: "YOU'RE LOGGED IN.",
        secondary: "Good luck! Have fun!",
        type: "success",
      });
    }
  };

  return (
    <div className="main">
      <Intro
        primary="DARK MATTER CONCEPTS"
        secondary="Here's where you'll find information about your project (i.e. the launch of your website), including
				status updates, things I need from you, helpful resources, and more! If you're Taylor or Russell..."
        tertiary="You're in the right place."
        pictures={[{ src: require("./dark-matter3x2.png"), alt: "DM" }]}
      />

      {loggedIn ? (
        <div className="column p01">
          <h2>OVERVIEW</h2>
          <div className="flex wrap">
            <Card primary="PROJECT COMPLETION" secondary="70%">
              <div className={css.graph}>
                <div
                  className={css.horizontal}
                  style={{ width: "calc(70% + 0.5rem)" }}
                >
                  <div className={css.ball} />
                </div>
              </div>
            </Card>
            <Card primary="MILESTONES" secondary="7 / 13">
              <div className={css.milestones}>
                <div className={css.milestone} />
                <div className={css.milestone} />
                <div className={css.milestone} />
                <div className={css.milestone} />
                <div className={css.milestone} />
                <div className={css.milestone} />
                <div className={css.milestone} />
                <div className={`${css.milestone} o16`} />
                <div className={`${css.milestone} o16`} />
                <div className={`${css.milestone} o16`} />
                <div className={`${css.milestone} o16`} />
                <div className={`${css.milestone} o16`} />
                <div className={`${css.milestone} o16`} />
              </div>
            </Card>
            <Card primary="ACTION ITEMS" secondary="0"></Card>
          </div>
          <h2 style={{ marginTop: "4rem" }}>DETAILS</h2>
          <Accordion open title="MILESTONES">
            <LI>
              <p>
                Customers can access and use the website, no matter what device
                or browser they're using.
              </p>
            </LI>
            <LI type="unchecked">
              <p>The website includes all necessary pages and sections.</p>
            </LI>
            <LI type="unchecked">
              <p>Customers can quickly and easily navigate the website.</p>
            </LI>
            <LI type="unchecked">
              <p>
                The website and its content are optimized for search engines.
              </p>
            </LI>
            <LI>
              <p>
                The website includes links to the company's social media
                profiles.
              </p>
            </LI>
            <LI type="unchecked">
              <p>
                The written copy is free of spelling and grammar errors and
                conveys the appropriate tone.
              </p>
            </LI>
            <LI type="unchecked">
              <p>The images and videos are high-quality and lightweight.</p>
            </LI>
            <LI>
              <p>The website loads quickly and performs well.</p>
            </LI>
            <LI>
              <p>Customers can buy a product using several payment methods.</p>
            </LI>
            <LI>
              <p>The website calculates and collects the appropriate taxes.</p>
            </LI>
            <LI>
              <p>
                The website helps with label printing and order fulfillment.
              </p>
            </LI>
            <LI>
              <p>The website tracks key analytics and user information.</p>
            </LI>
            <LI type="unchecked">
              <p>
                The website has been thoroughly tested and is ready for launch.
              </p>
            </LI>
            <strong>JANUARY 10, 2025</strong>
            <LI type="unchecked">
              <p>Launch!</p>
            </LI>
          </Accordion>
          <Accordion open title="RESOURCES">
            <LI type="unchecked">
              <p>Comprehensive pre-launch checklist.</p>
            </LI>
            <LI type="unchecked">
              <p>How to generate QR codes and use them effectively.</p>
            </LI>
            <LI type="unchecked">
              <p>
                How to perform tasks like viewing analytics reports and
                maintaining the website.
              </p>
            </LI>
            <LI type="unchecked">
              <p>How to Leverage Your Website to Scale Your Business</p>
            </LI>
            <LI type="unchecked">
              <p>Post-launch checklist.</p>
            </LI>
          </Accordion>
          <h2 style={{ marginTop: "4rem" }}>COLLABORATE</h2>
          <div className="flex wrap">
            <Card
              primary="CALL ME"
              button={
                <a href="tel:+18018678774" className="link">
                  801-867-8774
                </a>
              }
            />
            <Card
              primary="TEXT ME"
              button={
                <a href="sms:+18018678774" className="link">
                  801-867-8774
                </a>
              }
            />
            <Card
              primary="EMAIL ME"
              button={
                <a href="mailto:jacob.w.moreno@gmail.com" className="link">
                  jacob.w.moreno@gmail.com
                </a>
              }
            />
          </div>
        </div>
      ) : (
        <form onSubmit={submit} className="column p01">
          <Input
            label="enter the password"
            onChange={changePassword}
            type="password"
            value={password}
            error={passwordError}
          />
          <button type="submit">LOG IN</button>
        </form>
      )}
    </div>
  );
};

export default DarkMatter;
