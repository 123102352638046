import React from "react";
import Intro from "../../../components/intro/Intro";

const Manage = () => {
  return (
    <div className="main">
      <Intro
        primary="AAAND WE'RE LIVE!"
        secondary="here's where you can manage the current sweepstakes."
      />
    </div>
  );
};

export default Manage;
