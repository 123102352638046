import React from "react";
import css from "./Intro.module.css";

const Small = ({ children, primary, secondary }) => {
  return (
    <div className={css.small}>
      <div className={css.container}>
        <h1>{primary}</h1>
        <p className="o64 ctext">{secondary}</p>
        {children}
      </div>
    </div>
  );
};

export default Small;
