import React, { createContext, useCallback, useState } from "react";

const CartContext = createContext({
  add: () => {},
  addToCart: () => {},
  cart: [],
  clearCart: () => {},
  createOrder: () => {},
  divide: () => {},
  multiply: () => {},
  order: {},
  options: {},
  prices: {},
  removeFromCart: () => {},
  subtract: () => {},
});

export const CartContextProvider = (props) => {
  const previousCart = JSON.parse(sessionStorage.getItem("cart")) || [];
  const previousOrder = JSON.parse(sessionStorage.getItem("order")) || null;

  const [cart, setCart] = useState(previousCart);
  const [order, setOrder] = useState(previousOrder);

  const prices = {
    customization: {
      cactus: 4,
      heart: 2,
      lightning: 3,
      star: 4,
    },
    style: {
      earring: 1,
      keychain: 1,
      necklace: 2,
      standalone: 0,
    },
  };

  const options = {
    clientSecret: order?.payment?.secret,
    appearance: {
      theme: "flat",

      variables: {
        colorBackground: "white",
        fontFamily: "Quicksand",
        colorText: "#161616",
        colorDanger: "#f2d2bd",
        gridRowSpacing: "1rem",
        gridColumnSpacing: "1rem",
      },

      rules: {
        ".Input": {
          backgroundColor: "rgba(255, 255, 255, 0.16)",
          color: "white",
          borderRadius: "2rem",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          padding: "0.55rem 0.8rem",
          fontSize: "1rem",
        },
        ".Input--empty": {
          backgroundColor: "white",
        },
        ".Input--invalid": {
          color: "#bc0000",
          backgroundColor: "#ffb8b8",
        },
        ".Label": { color: "#ffffffa3" },
        ".Label--empty": {
          color: "white",
        },
        ".Label--invalid": {
          color: "#f2d2bd",
        },
        ".CheckboxLabel": { color: "#ffffffa3" },
      },
    },
    fonts: [
      {
        family: "Quicksand",
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap",
        weight: "700",
      },
    ],
  };

  const add = (num1, num2) => {
    let number1 = parseFloat(num1);
    let number2 = parseFloat(num2);
    let result = (number1 * 100 + number2 * 100) / 100;
    return parseFloat(result.toFixed(2));
  };

  const divide = (num1, num2) => {
    let number1 = parseFloat(num1);
    let number2 = parseFloat(num2);
    let result;
    if (number2 === 0) {
      console.warn("CAN'T DIVIDE BY 0.");
      result = null;
    }
    result = Math.round(((number1 * 100) / (number2 * 100)) * 100) / 100;
    return parseFloat(result.toFixed(2));
  };

  const multiply = (num1, num2) => {
    let number1 = parseFloat(num1);
    let number2 = parseFloat(num2);
    let result = Math.round(number1 * 100 * (number2 * 100)) / 10000;
    return parseFloat(result.toFixed(2));
  };

  const subtract = (num1, num2) => {
    let number1 = parseFloat(num1);
    let number2 = parseFloat(num2);
    let result = (number1 * 100 - number2 * 100) / 100;
    return parseFloat(result.toFixed(2));
  };

  const addToCart = useCallback(
    (item) => {
      setCart((cart) => {
        let newCart = [...cart, item];
        sessionStorage.setItem("cart", JSON.stringify(newCart));
        return newCart;
      });
      sessionStorage.removeItem("order");
      setOrder(null);
    },
    [setCart]
  );

  const clearCart = useCallback(() => {
    setCart([]);
    setOrder(null);
    sessionStorage.removeItem("cart");
    sessionStorage.removeItem("order");
  }, []);

  const createOrder = useCallback((order) => {
    setOrder(order);
    sessionStorage.setItem("order", JSON.stringify(order));
  }, []);

  const removeFromCart = useCallback(
    (id) => {
      setCart((cart) => {
        let newCart = cart.filter((item) => item.id !== id);
        sessionStorage.setItem("cart", JSON.stringify(newCart));
        return newCart;
      });
      sessionStorage.removeItem("order");
      setOrder(null);
    },
    [setCart]
  );

  const context = {
    add,
    addToCart,
    cart,
    clearCart,
    createOrder,
    divide,
    multiply,
    order,
    options,
    prices,
    removeFromCart,
    subtract,
  };

  return (
    <CartContext.Provider value={context}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContext;
