import React from "react";
import { Link } from "react-router-dom";
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";
import Accordion from "../../components/accordion/Accordion";
import Card from "../../components/card/Card";
import Small from "../../components/intro/Small";
import LI from "../../components/LI/LI";
import Slider from "../../components/slider/Display";

const WoodBurning = () => {
  const woodburnings = [
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/potter.webp",
      alt: "a close-up shot of harry potter (left) and voldemort (right) staring intensely into each other's eyes. voldemort is holding the elder wand vertically between their faces.",
      description:
        "i actually made this one for practice and gave it away to a stranger on the internet.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
      alt: "a husband (right) and wife (left) looking at each other with their foreheads and noses touching. the wife is in a wedding dress; the husband is in a suit. it is cropped to just their torsos; a bouquet of flowers sprouts up from between them.",
      description:
        "a friend of mine commissioned this wood burning as a christmas gift.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/halo.webp",
      alt: "master chief (from the 'halo' series) in his signature armor holding a battle rifle. he is in an active stance, but the rifle is lowered. the word 'HALO' is written in the signature font above him.",
      description:
        "this commission was one of my earliest wood burnings. i made it with a $20 wood burner i found at walmart.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/jesus.webp",
      alt: "a close-up shot of jesus' head and torso. he is looking to the left with a soft gaze. his hair is slightly curly, flowing over his ears but behind his shoulders.",
      description: "i made this as a gift for a family member.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/elsa.webp",
      alt: "disney's elsa standing at a 3/4 angle looking into the camera while smirking with one eyebrow raised.",
      description:
        "someone on twitter commissioned this from me for their daughter.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/art/cover.webp",
      alt: "a wedding guest book cover. from top to bottom, it reads: 'THE GUEST BOOK OF', 'David Teuscher', 'AND', 'Lara Vasilyeva', 'AUGUST SEVENTH TWENTY TWENTY', 'PROVO, UTAH'.",
      description: "a wedding guest book cover a friend commissioned from me.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/gaten.webp",
      alt: "a close-up shot of gaten matarazzo's face. he is young ('stranger things' season 1 young), smiling, and looking into the camera.",
      description:
        "my neighbor purchased this for his daughter who loves 'stranger things'.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/capone.webp",
      alt: "a dog (i'm not sure which kind, sorry). it's stout with short hair and small, triangular ears.",
      description: "i made this to honor my friend's late dog.",
    },
  ];

  return (
    <div className="main">
      <Small
        primary="WOOD BURNING"
        secondary="i'm a professional pyrographer, which is just a fancy way of saying i'd love to burn your portrait into wood."
      />
      <div className="column p01">
        <h2>FOR SALE</h2>
        <p className="o64 ctext">sold out! check back soon.</p>

        <h2 style={{ marginTop: "4rem" }}>SOLD</h2>
        <p className="o64 ctext">
          here are a few examples. please bear with me as i work on adding more!
          i used to not take pictures of my work, and now i make a lot of pieces
          for funerals, and i don't post those pieces unless they give me
          permission.
        </p>
        <Pictures pictures={woodburnings} />

        <h2 style={{ marginTop: "4rem" }}>CUSTOM ORDERS</h2>
        <Accordion title="GET IN TOUCH">
          <LI>
            <p>
              send me the picture(s) you'd like me to use as reference. make
              sure the picture is clear, with nothing blocking the subject. (if
              the pictures don't work, i'll let you know.)
            </p>
          </LI>
          <LI>
            <p>
              then we'll hop on a call to discuss the project—and the subject.
              this step is especially meaningful for portrait pieces like
              memorials. i spend a lot of time staring at the person or pet, and
              it's nice to get to know them. plus, it helps me capture their
              essence.
            </p>
          </LI>
          <a href="tel:+18018678774" className="link">
            801-867-8774
          </a>
          <a href="mailto:jacob.w.moreno@gmail.com" className="link">
            jacob.w.moreno@gmail.com
          </a>
        </Accordion>
        <Accordion title="LET ME COOK">
          <LI>
            <p>once we're all set, i'll get started on your custom order!</p>
          </LI>
          <LI>
            <p>
              depending on your preferences, i can film the process—or not—it's
              completely up to you. (even if i film it, you get to decide whom
              to share it with.)
            </p>
          </LI>
        </Accordion>
        <Accordion title="SPECIAL DELIVERY">
          <LI>
            <p>
              when i'm finished, i'll find a way to deliver it to you, safe and
              sound—in person or via mail.
            </p>
          </LI>
          <LI>
            <p>
              and i'll include instructions for how to best display and care for
              it!
            </p>
          </LI>
        </Accordion>
        <Accordion title="PRICING">
          <Slider
            title="SINGLE PORTRAIT"
            minimum={0}
            maximum={3400}
            checkpoints={[
              { value: 600, label: "starting", type: "minimum" },
              { value: 1600, label: "highest", type: "maximum" },
            ]}
          />

          <div style={{ height: "2rem" }} />

          <Slider
            title="COUPLES PORTRAIT"
            minimum={0}
            maximum={3400}
            checkpoints={[
              { value: 1200, label: "starting" },
              { value: 2800, label: "highest" },
            ]}
          />
        </Accordion>
        <div className="flex wrap">
          <Card
            primary="[ TEXT / CALL ] ME"
            button={
              <a href="sms:+18018678774" className="link">
                801-867-8774
              </a>
            }
          />
          <Card
            primary="EMAIL ME"
            button={
              <a href="mailto:jacob.w.moreno@gmail.com" className="link">
                jacob.w.moreno@gmail.com
              </a>
            }
          />
        </div>

        <h2 style={{ marginTop: "4rem" }}>MORE TO SHOP</h2>
        <div className="flex wrap">
          <Card
            primary="WIRE NAMES"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/art/wire/falling.png",
              alt: "wire names scattered across a black background. Jacob is largest and in focus, with several smaller, out-of-focus names in the background: Ernesto, Ellie, Joy, Kaz, Jess, Maddie, Bubbles, and Ed.",
            }}
            button={
              <Link className="link" to="/wire-names">
                GET WIRE NAMES
              </Link>
            }
          />
          <Card
            primary="COLORED PAINCILS"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
              alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
            }}
            button={
              <Link className="link" to="/colored-paincils">
                CHECK IT OUT
              </Link>
            }
          />
        </div>

        <h2 style={{ marginTop: "4rem" }}>MORE ART</h2>
        <Pictures
          key={1}
          pictures={[
            {
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
              description:
                "a friend of mine commissioned this wood burning as a christmas gift for his wife.",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/art/egg.webp",
              alt: "egg",
              description: "a hyper-realistic egg i drew using procreate.",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/logo/ss.jpg",
              alt: "squidslippers",
              description: "my squidslippers logo! i love this little guy!",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/art/pig.webp",
              alt: "pig",
              description: "an acrylic painting i made as a commission.",
            },
          ]}
        />
        <Link className="link" to={"/art"}>
          CHECK IT OUT
        </Link>
      </div>
    </div>
  );
};

export default WoodBurning;
