import React from "react";
import Card from "../../../components/card/Card";
import { Link } from "react-router-dom";

const EggDropSoup = () => {
  return (
    <Card
      primary="EGG DROP SOUP"
      placeholder
      button={
        <Link className="link" to="/recipe/egg-drop-soup">
          TRY IT
        </Link>
      }
    />
  );
};

export default EggDropSoup;
