import React, { useContext } from "react";
import css from "../Food.module.css";
import Timer from "../Timer";
import Intro from "../../../components/intro/Intro";
import Step from "../Step";
import NotificationsContext from "../../../context/notifications";
import Card from "../../../components/card/Card";
import Pancakes from "../previews/Pancakes";
import EggDropSoup from "../previews/EggDropSoup";

const BrusselsSproutsAndBacon = () => {
  const { addNotification } = useContext(NotificationsContext);
  return (
    <div className="main">
      <Intro
        primary="BRUSSELS SPROUTS + BACON"
        secondary="this cooks enough for 2 people to have a solid meal, or for 4-6 people to share as a side or appetizer. even if you're convinced you don't like brussels sprouts (like i was), i'm convinced this dish will convince you otherwise. how convincing!"
        pictures={[
          {
            src: "https://storage.googleapis.com/squidslippers-media/food/brussels_sprouts_bacon.webp",
            alt: "brussels sprouts and bacon",
          },
        ]}
      />
      <div className="column p01">
        <h2>SHOP IT</h2>
        <div className="columns">
          <div className={css.instruction}>
            <strong>INGREDIENTS</strong>
            <Step text="bacon" right="6 pieces" />
            <Step text="brussels sprouts" right="1 pound" />
            <Step text="water" right="1/3 cup" />
            <Step text="salt" right="to taste" />
            <Step text="pepper" right="to taste" />
            <Step text="lemon juice" right="1/2 tablespoon" />
          </div>
          <div className={css.instruction}>
            <strong>TOOLS</strong>
            <Step text="stove" right="1" />
            <Step text="pan (large)" right="1" />
            <Step text="pan lid (large)" right="1" />
            <Step text="spatula" right="1" />
            <Step text="cutting board" right="1" />
            <Step text="knife" right="1" />
            <Step text="1/3 cup" right="1" />
            <Step text="1/2 tablespoon (optional)" right="1" />
          </div>
        </div>
        <h2 style={{ marginTop: "4rem" }}>MAKE IT</h2>
        <div className={css.instruction}>
          <Step text="put the pan on the stove and turn the heat to medium-high." />
        </div>
        <p className="ctext o64">while the pan is heating up...</p>
        <div className={css.instruction}>
          <Step text="chop the bacon into 1-inch pieces." />
        </div>
        <p className="ctext o64">once the pan is hot...</p>
        <div className={css.instruction}>
          <Step text="slide the bacon, bit by bit, into the pan." />
        </div>
        <Timer
          primary={
            <p className="ctext">
              let the bacon cook until it's crispy and the fat is rendered (5-10
              minutes).
            </p>
          }
          initialMinutes={10}
        />
        <p className="ctext o64">while the bacon is cooking...</p>
        <div className={css.instruction}>
          <Step text="cut the stem edge off of the brussels sprouts." />
        </div>
        <div className={css.instruction}>
          <Step text="cut the brussels sprouts into quarters." />
        </div>
        <p className="ctext o64">once the bacon is cooked...</p>
        <div className={css.instruction}>
          <Step text="add the brussels sprouts to the pan." />
        </div>
        <div className={css.instruction}>
          <Step text="add the water to the pan." />
        </div>
        <div className={css.instruction}>
          <Step text="sprinkle the brussels sprouts and bacon with salt and pepper." />
          <p className="ctext o64">
            a little goes a long way here—there are some strong flavors at play.
          </p>
        </div>
        <div className={css.instruction}>
          <Step text="put the lid on the pan." />
        </div>
        <Timer
          primary={
            <p className="ctext">
              let the brussels sprouts cook until they're tender (5-10 minutes).
            </p>
          }
          initialMinutes={7}
        />
        <div className={css.instruction}>
          <Step text="remove the lid from the pan." />
          <Step text="remove the pan from the heat." />
        </div>
        <div className={css.instruction}>
          <Step text="add the lemon juice to the pan and mix together." />
        </div>
        <button
          className="button"
          onClick={() =>
            addNotification({
              primary: "YOU DID IT!",
              secondary: "nice work!",
              type: "success",
              showTime: 4,
            })
          }
        >
          CELEBRATE!
        </button>
        <h2 style={{ marginTop: "4rem" }}>SERVE IT</h2>
        <div className="flex wrap">
          <Card primary="HOT, HOT, HOT!" />
          <Card primary="BOWL / PLATE" />
          <Card primary="FORK / SPOON" />
        </div>
        <p className="o64">
          i don't like doing dishes, so i usually put anika's portion into a
          bowl with a [ fork / spoon ] and eat mine right out of the pan with
          the spatula.
        </p>
        <h2 style={{ marginTop: "4rem" }}>MORE MEALS TO TRY</h2>
        <div className="flex wrap">
          <Pancakes />
          <EggDropSoup />
        </div>
      </div>
    </div>
  );
};

export default BrusselsSproutsAndBacon;
