import React, { useContext } from "react";
import css from "../Food.module.css";
import Card from "../../../components/card/Card";
import Step from "../Step";
import { Link } from "react-router-dom";
import Intro from "../../../components/intro/Intro";
import NotificationsContext from "../../../context/notifications";
import Pancakes from "../previews/Pancakes";
import BrusselsSproutsAndBacon from "../previews/BrusselsSproutsAndBacon";
import EggDropSoup from "../previews/EggDropSoup";

const IncineratedToast = () => {
  const { addNotification } = useContext(NotificationsContext);

  return (
    <div className="main">
      <Intro
        primary="INCINERATED TOAST"
        secondary="admittedly, this is one of my least favorite recipes to make, yet i keep coming back to it time and time again. it's easy too make, but everything about it is simply awful. enjoy!"
        pictures={[{ src: require("../toast.png"), alt: "burnt toast" }]}
      />
      <div className="column p01" style={{ position: "relative" }}>
        <h2>HOW TO SHOP IT</h2>
        <div className="columns">
          <div className={css.instruction}>
            <strong>INGREDIENTS</strong>
            <Step text="bread (slice)" right="1" />
          </div>
          <div className={css.instruction}>
            <strong>TOOLS</strong>
            <Step text="oven" right="1" />
            <Step text="oven fan" right="1" />
            <Step text="garbage can" right="1" />
          </div>
        </div>
        <h2>HOW TO MAKE IT</h2>
        <div className={css.instruction}>
          <Step text="add the bread to the oven." />
        </div>
        <div className={css.instruction}>
          <Step text="turn on the broiler and set the temperature to high." />
        </div>
        <div className={css.instruction}>
          <Step text="leave the kitchen and do something else entirely." />
          <p className="o64 ctext">
            it doesn't matter what you do here, so long as you are completely
            immersed in the activity and out of the kitchen.
          </p>
        </div>
        <p className="ctext">
          leave the bread slice in the broiler until it is completely inedible
          (15 - 60 minutes).
        </p>
        <div className={css.instruction}>
          <Step text="return to the kitchen in a panic." />
        </div>
        <div className={css.instruction}>
          <Step text="turn off the broiler." />
          <Step text="turn on the kitchen fan." />
          <Step text="open any doors and windows near the preparation area." />
        </div>
        <div className={css.instruction}>
          <Step text="remove the toast from the oven." />
        </div>
        <div className={css.instruction}>
          <Step text="add the toast to the garbage." />
        </div>
        <button
          className="button"
          onClick={() =>
            addNotification({
              primary: "BOO!",
              secondary: "i hope you hate it!",
              type: "error",
              showTime: 4,
            })
          }
        >
          CELEBRATE!
        </button>
        <h2>HOW TO SERVE IT</h2>
        <div className="flex wrap">
          <Card primary="DON'T" />
        </div>
        <h2>MORE MEALS TO TRY</h2>
        <div className="flex wrap">
          <BrusselsSproutsAndBacon />
          <Pancakes />
          <EggDropSoup />
        </div>
      </div>
    </div>
  );
};

export default IncineratedToast;
