import React, { useContext, useState } from "react";
import css from "./ColoredPaincils.module.css";
import CartContext from "../../context/cart";
import { useHttp } from "../../helpers/useHttp";

const Form = ({ close }) => {
  const [chosen, setChosen] = useState("");
  const [loading, setLoading] = useState(false);

  const { sendRequest } = useHttp();

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let savedEntry = await sendRequest(
        "cart/items",
        "POST",
        JSON.stringify({
          title: "colored paincil",
          specifics: {
            color: chosen,
          },
        })
      );

      addToCart(savedEntry);
      close();
    } catch (error) {}
    setLoading(false);
  };

  const { addToCart } = useContext(CartContext);

  const colors = [
    "white",
    "pink",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "purple",
    "black",
  ];

  let disabled = !chosen;

  return (
    <div onClick={close} className={css.overlay}>
      <form
        onSubmit={submit}
        onClick={(e) => e.stopPropagation()}
        className={css.form}
      >
        <h2>CHOOSE A COLOR</h2>
        <div className="flex wrap">
          {colors.map((color, index) => {
            return (
              <button
                key={index}
                className={`${css.button} ${
                  chosen ? (color === chosen ? null : css.ignored) : null
                }`}
                onClick={() => setChosen(color)}
                type="button"
              >
                <div className={`${css.color} ${css[color]}`} />
                {color}
              </button>
            );
          })}
        </div>
        <button
          type="submit"
          className={`button ${disabled ? "disabled" : ""}`}
          disabled={disabled}
        >
          {loading ? "LOADING..." : "ADD TO CART"}
        </button>
        <button type="button" onClick={close} className="link">
          CANCEL
        </button>
      </form>
    </div>
  );
};

export default Form;
