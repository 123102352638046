import React, { useContext, useEffect, useState } from "react";
import Small from "../../components/intro/Small";
import Input from "../../components/input/Input";
import Accordion from "../../components/accordion/Accordion";
import { AddressElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHttp } from "../../helpers/useHttp";
import CartContext from "../../context/cart";
import NotificationsContext from "../../context/notifications";
import Form from "./Form";
import Card from "../../components/card/Card";
import { Link } from "react-router-dom";

const NameOfTheDay = () => {
  const { options } = useContext(CartContext);
  const { addNotification } = useContext(NotificationsContext);
  const { sendRequest } = useHttp();

  const [remaining, setRemaining] = useState();
  const [name, setName] = useState("");
  const [status, setStatus] = useState();
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [customer, setCustomer] = useState("");

  const [emailError, setEmailError] = useState("");

  const stripe = loadStripe(
    process.env.NODE_ENV === "production"
      ? "pk_live_51PG1cBE8JDSpBeryg35KamxhzNgB88h7R8dgOaBYm2RuEjDfGWBWYfnEhVcLnhBUPMtuWR8bphiT25gm3TePTLKi00BzYOqn1C"
      : "pk_test_51PG1cBE8JDSpBerypldkR24EqpIqmTUTtiLT32gnvIeu1eKpAuy2OF3UnCcfM4v44ChBPaHTE11fDepFhR7VUL6o006FU8KLOH"
  );

  const changeEmail = (e) => {
    setEmailError("");
    setEmail(e.target.value);
  };

  const changeInfo = (e) => {
    setCustomer(e.value);
  };

  const getName = async () => {
    try {
      setStatus("preparing");

      let { name, remaining } = await sendRequest("name-of-the-day");

      setName(name);
      setRemaining(remaining);

      if (!remaining > 0) setStatus("out");
      else setStatus("");
    } catch (error) {}
  };

  const submit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      validate();
      let name = await sendRequest(
        "name-of-the-day",
        "POST",
        JSON.stringify({
          ...customer,
          email,
        })
      );

      if (name.out) {
        setStatus("out");
        addNotification({
          primary: "TOO SLOW!",
          secondary: "someone else claimed it. sorry!",
          type: "error",
          showTime: 4,
        });
      }

      setStatus("gotcha");
    } catch (error) {
      addNotification({
        primary: "OOPS...",
        secondary: "i couldn't process your request.",
        type: "error",
        showTime: 4,
      });
    }
    setLoading(false);
    return;
  };

  function validate() {
    let error = false;

    if (!email) {
      error = true;
      setEmailError("Enter your email!");
    }

    if (
      !customer.name ||
      !customer.address.line1 ||
      !customer.address.city ||
      !customer.address.postal_code ||
      !customer.address.state
    ) {
      error = true;
    }

    if (error) {
      console.log(`error: ${JSON.stringify(error)}`);
      throw {
        code: 400,
        name: "INVALID INPUT",
      };
    }
  }

  let disabled =
    loading ||
    !email ||
    !customer.name ||
    !customer.address.line1 ||
    !customer.address.city ||
    !customer.address.postal_code ||
    !customer.address.state;

  useEffect(() => {
    getName();
  }, []);

  switch (status) {
    case "preparing":
      return <Small primary="NAME OF THE DAY" secondary="loading..." />;
    case "gotcha":
      return (
        <div className="main">
          <Small
            primary="NICE WORK!"
            secondary={`you got the name of the day.`}
          />
          <h2 style={{ marginTop: "4rem" }}>MORE TO SHOP</h2>
          <div className="flex wrap">
            <Card
              primary="COLORED PAINCILS"
              img={{
                src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
                alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
              }}
              button={
                <Link className="link" to="/colored-paincils">
                  CHECK IT OUT
                </Link>
              }
            />
            <Card
              primary="WOOD BURNING"
              button={
                <Link className="link" to="/wood-burning">
                  GET A WOOD BURNING
                </Link>
              }
              img={{
                src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
                alt: "a husband and wife looking at each other",
              }}
            />
          </div>
        </div>
      );

    case "out":
      return (
        <div className="main">
          <Small
            primary="ALL GONE"
            secondary="try again tomorrow—or just buy some!"
          />
          <div className="column p01">
            <Form />

            <h2 style={{ marginTop: "4rem" }}>MORE TO SHOP</h2>
            <div className="flex wrap">
              <Card
                primary="COLORED PAINCILS"
                img={{
                  src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
                  alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
                }}
                button={
                  <Link className="link" to="/colored-paincils">
                    CHECK IT OUT
                  </Link>
                }
              />
              <Card
                primary="WOOD BURNING"
                button={
                  <Link className="link" to="/wood-burning">
                    GET A WOOD BURNING
                  </Link>
                }
                img={{
                  src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
                  alt: "a husband and wife looking at each other",
                }}
              />
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div className="main">
          <Small
            primary={`${name.toUpperCase()}`}
            secondary="is the name of the day."
          >
            <strong style={{ marginTop: "1rem" }} className="o64 ctext">
              {remaining} remaining
            </strong>
          </Small>
          <div className="column p01">
            <h2>GET IT FREE</h2>
            <form onSubmit={submit}>
              <Input
                label="Email"
                value={email}
                error={emailError}
                onChange={changeEmail}
                autoFocus
              />
              <div style={{ width: "100%" }}>
                <Elements stripe={stripe} options={options}>
                  <AddressElement
                    onChange={changeInfo}
                    options={{
                      mode: "shipping",
                      autocomplete: {
                        mode: "google_maps_api",
                        apiKey: "AIzaSyCY-Fab0KEuQoeHjYw-_O5u5Zz6iija6v0",
                      },
                    }}
                  />
                </Elements>
              </div>
              <button
                className={`button ${disabled ? "disabled" : ""}`}
                disabled={disabled}
                type="submit"
              >
                {loading ? "LOADING..." : `GET ${name.toUpperCase()} FREE`}
              </button>
            </form>

            <h2 style={{ marginTop: "4rem" }}>MORE TO SHOP</h2>
            <div className="flex wrap">
              <Card
                primary="WIRE NAMES"
                img={{
                  src: "https://storage.googleapis.com/squidslippers-media/art/wire/falling.png",
                  alt: "wire names scattered across a black background",
                }}
                button={
                  <Link className="link" to="/wire-names">
                    GET WIRE NAMES
                  </Link>
                }
              />
              <Card
                primary="COLORED PAINCILS"
                img={{
                  src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
                  alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
                }}
                button={
                  <Link className="link" to="/colored-paincils">
                    CHECK IT OUT
                  </Link>
                }
              />
              <Card
                primary="WOOD BURNING"
                button={
                  <Link className="link" to="/wood-burning">
                    GET A WOOD BURNING
                  </Link>
                }
                img={{
                  src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
                  alt: "a husband and wife looking at each other",
                }}
              />
            </div>
          </div>
        </div>
      );
  }
};

export default NameOfTheDay;
