import React from "react";
import Intro from "../../components/intro/Intro";
import Step from "../cookbook/Step";
import { Link } from "react-router-dom";
import Accordion from "../../components/accordion/Accordion";

const PreLaunch = () => {
  return (
    <div className="main">
      <Intro
        primary="E-COMMERCE PRE-LAUNCH CHECKLIST"
        secondary="A comprehensive pre-launch checklist for e-commerce websites. If you're preparing to launch an e-commerce website and want to make sure you're ready..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>LAY THE GROUNDWORK</h2>
        <div className="background">
          <Step text="Choose your e-commerce platform." />
        </div>
        <div className="background">
          <Step text="Register and connect your domain name." />
        </div>
        <div className="background">
          <Step text="Design your website." />
          <Step text="Create high-quality content." />
          <Step text="Build your website." />
        </div>
        <Accordion title="RELATED RESOURCES">
          <Link className="link">HOW TO CHOOSE THE RIGHT PLATFORM</Link>
          <Link className="link">HOW TO STRUCTURE A WEBSITE</Link>
          <Link className="link">WEBSITE DESIGN 101</Link>
          <Link className="link">MASTERING MOBILE RESPONSIVENESS</Link>
          <Link className="link">DEMYSTIFYING SEO</Link>
          <Link className="link">THE BASICS OF PHOTOGRAPHY</Link>
        </Accordion>

        <h2>SET UP SHOP</h2>
        <div className="background">
          <Step text="Add your products." />
        </div>
        <div className="background">
          <Step text="Choose which payment options you'll accept." />
        </div>
        <div className="background">
          <Step text="Set your shipping options and rates." />
        </div>
        <div className="background">
          <Step text="Make sure the appropriate taxes are collected." />
        </div>
        <div className="background">
          <Step text="Make sure the appropriate taxes are collected." />
        </div>
        <Accordion title="RELATED RESOURCES">
          <Link className="link">HOW TO CHOOSE SALES CHANNELS</Link>
        </Accordion>
      </div>
    </div>
  );
};

export default PreLaunch;
