import React from "react";
import css from "./ColoredPaincil.module.css";

const ColoredPaincil = ({ paincil, onClick }) => {
  const { color } = paincil.specifics;

  return (
    <div className={`${css.main}`}>
      <div
        className={`${css.paincil} ${
          css[`paincil_${paincil.specifics.color}`]
        }`}
      >
        <div className={css.delete} onClick={() => onClick(paincil.id)} />

        <p>{paincil.specifics.color}</p>
      </div>

      <div className={`${css.tip} ${css[`tip_${color}`]}`} />

      <p className={css.price}>${paincil.price}</p>
    </div>
  );
};

export default ColoredPaincil;
