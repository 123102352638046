import React, { useContext, useState } from "react";
import {
  AddressElement,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import css from "./Form.module.css";
import Input from "../../components/input/Input";
import { useHttp } from "../../helpers/useHttp";
import CartContext from "../../context/cart";
import NotificationsContext from "../../context/notifications";
import { Link } from "react-router-dom";

const Form = () => {
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const { sendRequest } = useHttp();
  const { order } = useContext(CartContext);
  const { addNotification } = useContext(NotificationsContext);

  const changeEmail = (e) => {
    setEmailError("");
    setEmail(e.target.value);
  };

  const changeInfo = (e) => {
    setCustomer(e.value);
  };

  const pay = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      validate();

      order.customer = { ...customer, email };

      let response = await sendRequest("order", "POST", JSON.stringify(order));

      // THIS EITHER PUSHES YOU TO THE return_url OR THROWS AN ERROR
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${
            process.env.NODE_ENV === "production"
              ? "https://squidslippers.com"
              : "http://localhost:3000"
          }/shop/order/${response.id}`,
        },
      });

      throw error;
    } catch (error) {
      addNotification({
        primary: "OOPS...",
        secondary: "we couldn't process your payment.",
        type: "error",
        showTime: 10,
      });
    }
    setLoading(false);

    return;
  };

  function validate() {
    let error = false;

    if (!email) {
      error = true;
      setEmailError("Enter your email!");
    }

    if (
      !customer.name ||
      !customer.address.line1 ||
      !customer.address.city ||
      !customer.address.postal_code ||
      !customer.address.state
    ) {
      error = true;
    }

    if (error) {
      throw {
        code: 400,
        name: "INVALID INPUT",
      };
    }
  }

  return (
    <form onSubmit={pay} className={css.main}>
      <h2>PAYMENT DETAILS</h2>
      <div style={{ width: "100%" }}>
        <PaymentElement />
      </div>
      {!loading && (
        <Input
          error={emailError}
          label="Email"
          onChange={changeEmail}
          type="email"
          value={email}
        />
      )}
      <div style={{ width: "100%" }}>
        <AddressElement
          onChange={changeInfo}
          options={{
            mode: "shipping",
            allowedCountries: ["US"],
            fields: {
              phone: "always",
            },
          }}
        />
      </div>
      <button disabled={loading} className="button" type="submit">
        {loading ? "LOADING..." : `PAY $${order.payment.total}`}
      </button>

      <Link to="/cart" className="link">
        BACK TO CART
      </Link>
    </form>
  );
};

export default Form;
