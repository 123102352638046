import React, { useContext } from "react";
import css from "./Footer.module.css";
import logo from "./logo.png";
import { Link, useLocation } from "react-router-dom";
import IAMContext from "../../context/iam";
import NotificationsContext from "../../context/notifications";

const Footer = () => {
  const { logOut, token } = useContext(IAMContext);
  const { addNotification } = useContext(NotificationsContext);
  const location = useLocation();

  if (location.pathname === "/name-list") return null;

  const logoutHandler = () => {
    addNotification({
      primary: "bye!",
      secondary: "you've been logged out.",
      type: "success",
      showTime: 5,
    });
    logOut();
  };

  return (
    <div className={css.main}>
      <Link to="/">
        <img
          className={css.logo}
          src={logo}
          alt="the squidslippers logo: a white squid-shaped creature with two 'S'-shaped tentacles."
        />
      </Link>
      <nav className={css.container}>
        {token && (
          <div className={css.row}>
            <strong className={css.title}>ADMIN</strong>
            <div className={css.links}>
              <Link className={`${css.link} o64`} to="/admin/dashboard">
                dashboard
              </Link>
              <Link className={`${css.link} o64`} to="/admin/live/manage">
                manage live
              </Link>
              <Link className={`${css.link} o64`} to="/admin/live/view">
                view live
              </Link>
              <button
                className={`${css.link} o64`}
                style={{ width: "fit-content" }}
                onClick={logoutHandler}
              >
                log out
              </button>
            </div>
          </div>
        )}
        <div className={css.row}>
          <strong className={css.title}>SHOP</strong>
          <div className={css.links}>
            <Link className={`${css.link} o64`} to="/colored-paincils">
              colored paincils
            </Link>
            <Link className={`${css.link} o64`} to="/wire-names">
              wire names
            </Link>
            <Link className={`${css.link} o64`} to="/wood-burning">
              wood burning
            </Link>
          </div>
        </div>
        <div className={css.row}>
          <strong className={css.title}>COLLABORATE</strong>
          <div className={css.links}>
            <Link className={`${css.link} o64`} to="/software-and-websites">
              software + websites
            </Link>
            <Link className={`${css.link} o64`} to="/content-and-design">
              content + design
            </Link>
          </div>
        </div>
        <div className={css.row}>
          <strong className={css.title}>RESOURCES</strong>
          <div className={css.links}>
            <Link className={`${css.link} o64`} to="/food">
              virtual cookbook
            </Link>
            <a
              className={`${css.link} o64`}
              href="https://cloudmoney.wm.r.appspot.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              cloud money
            </a>
          </div>
        </div>
        <div className={css.row}>
          <strong className={css.title}>ME</strong>
          <div className={css.links}>
            <Link className={`${css.link} o64`} to="/art">
              art
            </Link>
            {/* <Link className={`${css.link} o64`} to="/family">
              family
            </Link>
            <Link className={`${css.link} o64`} to="/fun">
              fun
            </Link>
            <Link className={`${css.link} o64`} to="/meeeee">
              meeeee
            </Link> */}
            <Link className={`${css.link} o64`} to="/photography">
              photography
            </Link>
            <Link className={`${css.link} o64`} to="/social-media">
              social media
            </Link>
            <Link
              className={`${css.link} ${css.invisilink} o64`}
              to="/admin/login"
            >
              log in
            </Link>
            {/* <Link className={`${css.link} o64`} to="/travel">
              travel
            </Link>
            <Link className={`${css.link} o64`} to="/writing">
              writing
            </Link> */}
          </div>
        </div>
        <div className={css.row}>
          <strong className={css.title}>CONTACT</strong>
          <div className={css.links}>
            <a
              className={`${css.link} o64`}
              href="mailto:jacob.w.moreno@gmail.com"
            >
              jacob.w.moreno@gmail.com
            </a>
            <a className={`${css.link} o64`} href="sms:+18018678774">
              801-867-8774
            </a>
          </div>
        </div>
        <div className={css.row}>
          <strong className={css.title}>LEGAL</strong>
          <div className={css.links}>
            <span className={`${css.link} o64`}>©SquidSlippers, LLC 2024</span>
            <Link className={`${css.link} o64`} to="/privacy-policy">
              privacy policy
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Footer;
