import React from "react";
import css from "./Diglett.module.css";

const Diglett = ({ diglett, onClick }) => {
  return (
    <div className={css.main}>
      <div className={css.diglett}>
        <div className={css.delete} onClick={() => onClick(diglett.id)} />

        <p>diglett</p>
      </div>

      <div className={css.head} />

      <p className={css.price}>$25</p>
    </div>
  );
};

export default Diglett;
